import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import {
    Box,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Center,
    Spinner, Td, Button,
    Divider,
} from '@chakra-ui/react';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const directoryPath = '/api/okta/directory';
const securityPath = '/api/okta/directory/security';

function DirectoryList({ list }) {
    const dirKey = ((i) => `idpTable-${i}`);

    const directoryNames = list.map((user, i) => (
        <Tr key={dirKey(i)}>
            <Td>{user.name}</Td>
            <Td>{user.title}</Td>
            <Td>{user.email}</Td>
            <Td>{user.phone}</Td>
            <Td>{user.role}</Td>
            <Td>{user.oktaId}</Td>
        </Tr>
    ));

    return (
        <Table variant="simple">
            <Thead>
                <Tr>
                    <Th>Name</Th>
                    <Th>Job Title</Th>
                    <Th>Email</Th>
                    <Th>Mobile</Th>
                    <Th>Role</Th>
                    <Th>Okta Sub</Th>
                </Tr>
            </Thead>
            <Tbody>
                {directoryNames}
            </Tbody>
        </Table>
    );
}

DirectoryList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};
DirectoryList.defaultProps = { list: [] };

const updateSecurity = (async (accessToken) => {
    const url = labkoatApiUrl + securityPath;
    console.log(`Ping API: ${url}`);
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    try {
        const res = await fetch(url, options);
        if (res.status === 200) {
            console.log('Successful security update');
        }
    } catch (e) {
        console.log(e.message);
    }
});

const participantOmc = (list) => {
    const directory = [];
    list.forEach((p) => {
        if (p.structuralCharacteristics.structuralType === 'person') {
            const { Person } = p.structuralCharacteristics;
            const { Role } = p.functionalCharacteristics;
            const { name } = Person;
            const email = Person?.contact?.email?.business || 'N/A';
            const phone = Person?.contact?.telephone?.business || 'N/A';
            const oktaIdentifier = Person.identifier.filter((id) => id.identifierScope === 'okta');
            const oktaId = oktaIdentifier.length ? oktaIdentifier[0].identifierValue : 'N/A';
            const roles = Role.map((r) => r.roleType);
            directory.push({
                name,
                email,
                phone,
                oktaId,
                role: roles.join(', '),
            });
        }
    });
    return directory;
};

async function fetchDirectory(accessToken) {
    try {
        const url = labkoatApiUrl + directoryPath;
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const res = await fetch(url, options);
        const dirData = await res.json();
        const list = participantOmc(dirData.participants);
        console.log(list);
        return list;
    } catch (err) {
        console.log('Error');
        console.log(err);
        return [];
    }
}

function Directory() {
    const [dirList, updateDirList] = useState([]);
    const [isLoading, updateLoading] = useState(true);
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();

    useEffect(() => {
        fetchDirectory(accessToken)
            .then((data) => {
                updateDirList(data);
                updateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                updateLoading(false);
            });
    }, []);

    return isLoading
        ? (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        )
        : (
            <Box>
                <Box>
                    <DirectoryList list={dirList} />
                </Box>
                <Button m={5} onClick={() => updateSecurity(accessToken)}>Update Security</Button>
            </Box>
        );
}

export default Directory;
