import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Flex,
    Center, Spinner, Button, Spacer, Alert, AlertIcon, Stack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';

const makeArray = (val) => (Array.isArray(val) ? val : [val]);

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;

const identifierOfScope = ((identifier, scope) => identifier.filter((id) => id.identifierScope === scope)
    .map((id) => id.identifierValue)[0]);

// Call the admin API to get the list of tasks for this user
async function fetchTasks(accessToken) {
    const adminURL = `${labkoatApiUrl}/api/admin/task`;
    console.log(adminURL);
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    const res = await fetch(adminURL, options);
    if (res.status === 200) {
        return res.json();
    }
    if (res.status === 401) {
        return false;
    }
    return null; // An error (not authorized) was returned
}

// Filter out only the appropriate types of tasks for this page
function filterTasks(omc, taskType) {
    const tasks = makeArray(omc);
    return tasks.filter((t) => taskType.includes(t.functionalCharacteristics.functionalType));
}

function createButtonType(accessToken, omc, listFunction) {
    const { functionalType } = omc.functionalCharacteristics;
    let btText = functionalType;
    if (functionalType === 'publish') btText = 'publish to VFX';
    return (
        <Button bg="yellow.400" onClick={() => listFunction(accessToken, omc)}>
            {btText}
        </Button>
    );
}

function createListEntry(accessToken, omc, listFunction) {
    console.log(omc);
    const key = identifierOfScope(omc.identifier, 'labkoat');
    const { functionalType } = omc.functionalCharacteristics;
    const bgColor = functionalType === 'publish' ? 'green.100' : 'red.100';
    const button = createButtonType(accessToken, omc, listFunction);
    return (
        <Box key={key}>
            <Box border="2px" borderRadius="0.375rem" m="3" bg={bgColor}>
                <Flex m="2" key={identifierOfScope(omc.identifier, 'labkoat')}>
                    <Box mr="5" w="50">
                        <Text>{omc.name}</Text>
                    </Box>
                    <Box>
                        <Text>{omc.description}</Text>
                    </Box>
                    <Spacer />
                    <Box>
                        {button}
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
}

// Create a strip of images that together create a storyboard
function TaskList({
    listFunction,
    taskType,
}) {
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const [tasks, updateTasks] = useState([]);
    const [isLoading, updateLoading] = useState(true);

    useEffect(() => {
        updateLoading(true);
        fetchTasks(accessToken)
            .then((taskData) => {
                console.log(taskData);
                const filteredTasks = filterTasks(taskData, taskType);
                console.log(filteredTasks);
                if (taskData.length !== 0) {
                    updateTasks(filteredTasks);
                } else {
                    updateTasks([]);
                }
                updateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                updateLoading(false);
            });
    }, []);

    // Create a list of all the tasks, or a warning if there are no tasks
    let printList;
    if (tasks.length !== 0) {
        printList = tasks.map((lst) => createListEntry(accessToken, lst, listFunction));
    } else {
        printList = (
            <Alert status="warning">
                <AlertIcon />
                Currently there are no tasks in your list
            </Alert>
        );
    }

    return isLoading
        ? (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        )
        : (
            <Box margin={5}>
                {printList}
            </Box>
        );
}

TaskList.propTypes = {
    listFunction: PropTypes.func,
    taskType: PropTypes.arrayOf(PropTypes.string),
};

TaskList.defaultProps = {
    listFunction: () => {
    },
    taskType: [],
};

export default TaskList;
