import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Button,
    Card,
    CardBody,
    CardHeader,
    Text,
    Heading,
    Stack,
    CardFooter, Alert, AlertIcon, Center, Spinner,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';

import testFGA from '../data/policies';
import TaskList from '../Components/Layout/Utilities/TaskList';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;

function TokenExchange({
    buttonLabel,
    cardLabel,
    message,
    apiFunction,
}) {
    return (
        <Box m={3} key={cardLabel}>
            <Card>
                <CardHeader>
                    <Flex>
                        <Button onClick={apiFunction}>
                            {buttonLabel}
                        </Button>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Text>{message}</Text>
                </CardBody>
            </Card>
        </Box>
    );
}

function FgaButtons({ accessToken }) {
    const sendPolicy = (async (body) => {
        const adminURL = `${labkoatApiUrl}/api/admin/policy`;

        return fetch(adminURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(body),
        });
    });

    return testFGA.map((test) => (
        <Box m={3} key={test.title}>
            <Card>
                <CardHeader>
                    <Heading size="md">{test.title}</Heading>
                </CardHeader>
                <CardBody>
                    <Stack direction="row">
                        <Button onClick={() => sendPolicy(test.button1.body)}>
                            {test.button1.name}
                        </Button>
                        <Button onClick={() => sendPolicy(test.button2.body)}>
                            {test.button2.name}
                        </Button>
                    </Stack>
                </CardBody>
                <CardFooter />
            </Card>
        </Box>
    ));
}

async function administratorStatus(accessToken) {
    const adminURL = `${labkoatApiUrl}/api/admin/check`;
    console.log(adminURL);
    const res = await fetch(adminURL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return res.status === 200
        ? res.json()
        : { isAdministrator: false };
}

async function listFunction(accessToken, omc) {
    console.log('Admin button');
    const { identifier } = omc;
    const adminURL = `${labkoatApiUrl}/api/admin/task`;
    console.log(adminURL);
    const res = await fetch(adminURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(identifier),
    });
    if (res.status === 200) {
        console.log(res);
    } else {
        console.log(res.status);
    }
}

function Admin() {
    const {
        authState,
        oktaAuth,
    } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const [userIdToken, setIdToken] = useState(null); // Should access or id token be used
    const [userAccessToken, setAccessToken] = useState(null);
    const [isAdministrator, setIsAdministrator] = useState(false);
    const [isLoading, updateLoading] = useState(true);

    // useEffect(() => {
    //     const fetchInfo = async () => {
    //         // const info = await oktaAuth.getUser();
    //         const authTokens = await oktaAuth.token.getWithoutPrompt();
    //         const {
    //             idToken,
    //             accessToken,
    //         } = authTokens.tokens;
    //         setAccessToken(accessToken);
    //         setIdToken(idToken);
    //     };
    //
    //     if (!authState.isAuthenticated) {
    //         setIdToken(null); // When user isn't authenticated, forget any user info
    //     } else {
    //         fetchInfo();
    //     }
    // }, [authState, oktaAuth]); // Update if authState changes

    // Check if this user is an administrator
    useEffect(() => {
        administratorStatus(accessToken)
            .then((data) => {
                console.log(data);
                setIsAdministrator(data.isAdministrator);
                updateLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [setIsAdministrator]);

    // Ping the an endpoint on the server to get a response from the PEP
    const [editorMessage, setEditorMessage] = useState('Default Editor Message');
    const [ditMessage, setDitMessage] = useState('Default Dit Message');
    const [oktaMessage, setOktaMessage] = useState('Default Okta Message');

    const testExchange = async () => {
        console.log('Test Exchange');
        const bearerToken = userAccessToken.accessToken;
        const url = `${labkoatApiUrl}/api/test/exchange`;
        const response = await fetch(url, {
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${bearerToken}`,
            },
        });
        const message = await (response.json());
        console.log(message);
    };

    console.log(`Admin ${isAdministrator}`);

    const alert = isAdministrator
        ? (
            <Box />
        )
        : (
            <Box m="3">
                <Alert status="error">
                    <AlertIcon />
                    You are not an authorized administrator
                </Alert>
            </Box>
        );

    return isLoading
        ? (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        )
        : (
            <Box m="3">
                <TaskList
                    taskType={['publish', 'revoke']}
                    accessToken={accessToken}
                    listFunction={listFunction}
                />
                {alert}
            </Box>
        );
}

/*
                <TokenExchange
                    message="Token Exchange"
                    buttonLabel="Exchange"
                    apiFunction={testExchange}
                />
 */

export default Admin;
