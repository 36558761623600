import { contextRelations, identifierOfScope, OmcModel } from './omcUtil.mjs';
import getEntity from './getEntity.mjs';

const makeArray = ((v) => Array.isArray(v) ? v : [v]);

const bestIdentifier = ((ent, primaryScope) => {
    let primaryId = ent.identifier.filter((id) => id.identifierScope === primaryScope);
    return primaryId.length ? primaryId[0] : ent.identifier[0];
})

async function allRelatedNodes(omcContext, identifierScope, currentProject, accessToken) {
    const relations = contextRelations(omcContext);
    if (!relations) return []; // No relate things in the Context
    const relPrm = relations.reduce((ary, rlt) => {
        const entTypes = Object.keys(omcContext[rlt]);
        const entPromise = entTypes.flatMap((eType) => omcContext[rlt][eType].map((ent) => {
            const entId = bestIdentifier(ent, identifierScope); // Get the primary identifier or the first one
            console.log(entId);
            const params = { ...entId, ...{ currentProject }}
            return getEntity(params, accessToken);
        }));
        return [...ary, ...entPromise];
    }, []);
    try {
        return Promise.all(relPrm);
    } catch (err) {
        console.log(err);
        return [];
    }
}

function mapRelatedNodes(omcContext, relatedEntity, identifierScope) {
    const entMap = relatedEntity.reduce((obj, ent) => {
        // const id = ent.identifierValueOfScope(identifierScope);
        const id = (bestIdentifier(ent, identifierScope)).identifierValue
        return { ...obj, ...{ [id]: ent } };
    }, {});

    const relations = contextRelations(omcContext);
    if (!relations) return null // There are no related entities
    const r = relations.reduce((obj, rlt) => {
        const entTypes = Object.keys(omcContext[rlt]);
        const related = entTypes.reduce((entObj, eType) => {
            const nodes = omcContext[rlt][eType].map((ent) => {
                // return entMap[identifierOfScope(ent.identifier, identifierScope)];
                return entMap[(bestIdentifier(ent, identifierScope)).identifierValue];
            });
            return { ...entObj, ...{ [eType]: nodes } };
        }, {});
        return { ...obj, ...{ [rlt]: related } };
    }, {});
    return Object.assign(omcContext, r);
}

async function cxtEntities(omcContext, identifierScope, currentProject, accessToken) {
    // Fetch all the related nodes, remove bad responses where the id is missing
    const relatedNodes = (await allRelatedNodes(omcContext, identifierScope, currentProject, accessToken)).filter((d) => d);
    return mapRelatedNodes(omcContext, relatedNodes, identifierScope);
}

async function fetchOmc(omc, identifierScope, currentProject, accessToken) {

    // All the intrinsic relationships (including Context)
    const relatedEnt = omc.intrinsicRelations(); // Just the intrinsic properties and the id's for related entities

    if (Object.keys(relatedEnt).length === 0) return null;

    // Build a result object with edge names and array of all entities loaded in a Promise
    const relatedOmc = {};
    for (const eName in relatedEnt) {
        const entities = makeArray(relatedEnt[eName]);
        const entPrm = entities.map((ent) => {
            const entId = bestIdentifier(ent, identifierScope); // Get the primary identifier or the first one
            console.log(entId);
            const params = { ...entId, ...{ currentProject }}
            return getEntity(params, accessToken);
        });
        relatedOmc[eName] = Promise.all(entPrm);
    }

    // Update the OMC entity that was passed in, adding the related Context and other intrinsic properties
    let omcUpdate = OmcModel(omc);
    for (const eName in relatedEnt) {
        const intrinsicEntities = (await relatedOmc[eName]).filter((d) => d); // The entities on this edge (remove false)
        if (eName === 'Context') {
            const relatedEntities = intrinsicEntities.map((cxt) => cxtEntities(cxt, identifierScope, currentProject, accessToken));
            omcUpdate.Context = (await Promise.all(relatedEntities)).filter((d) => d);
        } else {
            omcUpdate[eName] = Array.isArray(omcUpdate[eName]) ? intrinsicEntities : intrinsicEntities[0];
        }
    }
    return omcUpdate;
}

export default fetchOmc;
