

const removeKeys = ['Context', 'For', 'name', 'description', 'schemaVersion', 'identifier', 'entityType', 'contextType'];

export function contextRelations(cxt) {
    const relations = Object.keys(cxt).filter((k) => !removeKeys.includes(k))
        .filter((k) => cxt[k] !== null);
    return relations.length !== 0 ? relations : null // Empty Contexts
}

export function identifierOfScope (identifier, scope) {
    return identifier.filter((id) => id.identifierScope === scope)
        .map((id) => id.identifierValue)[0]
}

export function canonicalName(omc) {
const canonicalFunc = {
    Character: (omc) => omc.characterName ? `${omc.characterName.fullName}` : null,
    NarrativeScene: (omc) => omc.sceneName ? `${omc.sceneName.fullName}` : null,
}
    const {entityType } = omc;
    return canonicalFunc[entityType]
        ? canonicalFunc[entityType](omc)
        :null;
}

const coreProps = ['identifier', 'schemaVersion', 'entityType', 'name', 'description'];
export function OmcModel(omc) {
    const model = Object.create({
        identifierOfScope(scope) {
            const identifier = this.identifier.filter((id) => id.identifierScope === scope);
            return identifier[0] ? identifier[0] : null;
        },
        identifierValueOfScope(scope) {
            const value = this.identifier.filter((id) => id.identifierScope === scope)
                .map((id) => id.identifierValue);
            return value[0] ? value[0] : null;
        },
        intrinsicProperties() {
            return Object.keys(this)
                .filter((e) => /^[A-Z]/.test(e))
                .filter((e) => this[e]);
        },
        intrinsicRelations() {
            const intrinsicEdges = this.intrinsicProperties()
            return intrinsicEdges.reduce((obj, eName) => {
                return { ...obj, ...{ [eName]: this[eName] } };
            }, {});
        },
        outputPropertyValues() {
            const formatProps = (obj) => {
                const keys = Object.keys(obj);
                return keys.flatMap((k) => {
                    if (Array.isArray(obj[k])) {
                        return `${k}: ${obj[k]}`;
                    }
                    else if (obj[k] === null) {
                        return `${k}: null`
                    }
                    else if (typeof obj[k] === 'object') {
                        const vals = formatProps(obj[k])
                        return vals.map((v) => `${k}.${v}`)
                    } else {
                        return `${k}: ${obj[k]}`
                    }

                })
            }
            const properties = Object.keys(this)
                .filter((e) => /^[a-z]/.test(e))
                .filter((p) => !coreProps.includes(p))

            const simpleProps = properties.reduce((obj, key) => {
                return { ...obj, ...{ [key]: this[key] } }
            }, {})

            return formatProps(simpleProps);

        },
        getName() {
            return this.name ? this.name : canonicalName(this)
        }
    });
    return Object.assign(model, omc);
}

