/**
 * Initiate an oAuth flow to acquire a token
 * @param config
 * @returns {{redirectUri, baseUrl, source, issuer}}
 * @constructor
 */

import jwtDecode from 'jwt-decode';

let authorizer = {
    originalUri: '/',
    baseUrl: null,
    issuerRoute: null,
    redirectUri: null,
    source: null,
    token: null,
};

function checkExpiration(jwt) {
    const timeStamp = Math.floor(Date.now() / 1000); // Current time in seconds
    const token = jwtDecode(jwt);
    // console.log(`Expiration: ${token.exp}, Current: ${timeStamp}, Diff: ${token.exp - timeStamp}`);
    // console.log(`Valid: ${(token.exp - timeStamp > 5)}`);
    return (token.exp && (token.exp - timeStamp > 5)) ? token : false;
}

/**
 * Configure the authorizer with details
 * @param params
 * @returns {{redirectUri: null, baseUrl: null, issuerRoute: null, source: null}|*|{redirectUri: null, baseUrl: null, issuerRoute: null, source: null}}
 */
function config(params = {}) {
    if (config === undefined) return authorizer; // Calls without params return current state
    authorizer = { ...authorizer, ...params }; // Update existing values
    return authorizer;
}

/**
 * Start the Authorize flow
 * @returns {{redirectUri: null, baseUrl: null, issuerRoute: null, source: null, originalUri: string, token: null}}
 */

function authorize() {
    const {
        originalUri,
        baseUrl,
        issuerRoute,
        redirectUri,
        source,
    } = config();

    let tryLogin = false; // Should a login attempt be made, default is no
    const timeNow = Math.floor(Date.now() / 1000);

    // Get the current authorization state from localstorage, checking for no state and bad values
    let authCurrentState = '';
    try {
        authCurrentState = JSON.parse(localStorage.getItem(source)); // Current state of msg login
    } catch (err) {
        console.log('Found something that was not JSON in the Messaging Login State');
    }

    // Make decisions based on the current state
    if (authCurrentState) {
        const { state } = authCurrentState || null; // What is the current state
        if (state === 'error' || state === 'loading') {
            // If loading or error present for over 5 seconds, then make another attempt
            if (timeNow - authCurrentState.timeStamp > 5) tryLogin = true;
        } else {
            // If there is not valid access or refresh token, make new login attempt
            const validAccess = (authCurrentState.access && checkExpiration(authCurrentState.access));
            // console.log(`Access valid: ${validAccess}`);
            const validRefresh = (authCurrentState.refresh && checkExpiration(authCurrentState.refresh));
            // console.log(`Refresh valid: ${validAccess}`);
            tryLogin = !(!!validAccess && !!validRefresh);
            // console.log(`Try logging in: ${tryLogin}`);
        }
    } else {
        tryLogin = true; // If no existing state the attempt login
    }

    if (tryLogin) {
        // Start the oAuth flow by firing the auth URL
        const loadingState = {
            timeStamp: timeNow,
            state: 'loading',
            originalUri,
            redirectUri,
        };
        localStorage.setItem(source, JSON.stringify(loadingState));
        const authUrl = `${baseUrl}${issuerRoute}&redirect=${redirectUri}`; // Auth URl to start flow
        window.open(authUrl, '_self');
    }
    return authorizer;
}

/**
 * Return the current state of the authorization process from the localstore
 * @returns {any}
 */

function getState() {
    const { source } = config();
    return JSON.parse(localStorage.getItem(source));
}

function getToken() {
    const currentState = getState(); // Current token if there is one
    const token = (currentState && currentState.token) ? currentState.token : null;

    // If there is no token then reauthorize
    if (!token) {
        console.log('Reauthorize - no token');
        config({ originalUri: '/messaging' });
        authorize();
        return false;
    }

    // Is the current token still valid or has it expired
    const validAccess = (token.access && checkExpiration(token.access)); // Is there a valid access token
    const validRefresh = (token.refresh && checkExpiration(token.refresh)); // Is there a valid refresh token

    // If it has expired reauthorize
    if (!validRefresh && !validAccess) {
        console.log('Reauthorize - expired token');
        config({ originalUri: '/messaging' });
        authorize();
        return false;
    }
    // console.log('Send back a valid token');
    return token;
}

function signOut() {
    const { source } = config();
    localStorage.removeItem(source);
    console.log('Sign out from messaging');
}

export default {
    config,
    authorize,
    getState,
    getToken,
    signOut,
};
