import React from 'react';
import { PropTypes } from 'prop-types';
import {
    Box, Heading,
    Text,
    useStyleConfig,
} from '@chakra-ui/react';

import { nodeParams } from './skosDefaults.mjs';
import { useSkosDictionary } from '../../../hooks/SkosDictionaryContext';

function SkosSideBar({
    sideBarContent = null, // Identifier for dictionary term to be displayed
}) {
    if (!sideBarContent) return null;

    const skosDictionary = useSkosDictionary();
    const sideBarBodyStyle = useStyleConfig('SideBarBody');
    const sideBarHeaderStyle = useStyleConfig('SideBarHeader');
    const display = skosDictionary.getEntity(sideBarContent); // The SKOS entity to be displayed

    if (!display) return null;

    const { type } = display;
    const headerParams = nodeParams[type]; // What type of node is this

    const prefLabel = typeof display.prefLabel === 'string'
        ? display.prefLabel
        : display.prefLabel.value;

    const altLabel = display.altLabel
        ? display.altLabel.map((l) => l.value)
            .join(', ')
        : 'N/A';

    const inScheme = display.inScheme
        ? display.inScheme.map((l) => l.prefLabel)
        : [];
    const schemes = inScheme.join(', ');

    return (
        <Box w="25%" overflow="auto">
            <Box __css={sideBarHeaderStyle} backgroundColor={headerParams.style.color} p={2}>
                <Heading size="sm" ml={1} mr={2}>{headerParams.label}</Heading>
            </Box>
            <Box __css={sideBarBodyStyle}>
                <Text fontWeight="bold">Preferred Label</Text>
                <Text>{prefLabel}</Text>
                <Text fontWeight="bold">Identifier</Text>
                <Text>{(display.id === '' || !display.id) ? 'N/A' : display.id}</Text>
                <Text fontWeight="bold">Status</Text>
                <Text>{display.status || 'N/A'}</Text>
                <Text fontWeight="bold">In Schemes</Text>
                <Text>{schemes}</Text>
                <Text fontWeight="bold">Alternative Label</Text>
                <Text>{altLabel}</Text>
                <Text fontWeight="bold">Definition</Text>
                <Text>{(display.definition === '' || !display.definition) ? 'N/A' : display.definition}</Text>
                <Text fontWeight="bold">Example</Text>
                <Text>{(display.example === '' || !display.example) ? 'N/A' : display.example}</Text>
                <Text fontWeight="bold">Editorial Note</Text>
                <Text>{(display.editorialNote === '' || !display.editorialNote) ? 'N/A' : display.editorialNote}</Text>
            </Box>
        </Box>
    );
}

SkosSideBar.propTypes = {
    sideBarContent: PropTypes.string, // The id of a dictionary entity to be displayed
};

export default SkosSideBar;
