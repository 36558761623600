import { extendTheme, defineStyleConfig } from '@chakra-ui/react';

const DisplayFrame = defineStyleConfig({
    baseStyle: {
        borderRadius: 'lg',
        borderColor: '#DDD',
        borderWidth: '1px',
        m: 1,
    },
});

const SideBarHeader = defineStyleConfig({
    baseStyle: {
        borderTopRadius: 'lg',
        borderColor: '#DDD',
        borderWidth: '1px',
        m: 1,
        mb: 0,
        p: 2,
    },
});

const SideBarBody = defineStyleConfig({
    baseStyle: {
        borderBottomRadius: 'lg',
        borderColor: '#DDD',
        background: '#ffffff',
        borderWidth: '1px',
        borderTopWidth: '0px',
        m: 1,
        mt: 0,
        p: 2,
    },
});

const colors = {
    primary: {
        100: '#CFD1FD',
        200: '#A7ABFB',
        300: '#8388F9',
        400: '#6268F8',
        500: '#444BF7',
        600: '#262EF6',
        700: '#0B14F5',
        800: '#0911DD',
        900: '#080FC7',
    },
};

const components = {
    DisplayFrame,
    SideBarHeader,
    SideBarBody,
};

const customTheme = extendTheme({
    colors,
    components,
});

export default customTheme;
