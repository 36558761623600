import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useToast } from '@chakra-ui/react';

import createSkosDictionary from '../services/vocabulary/skosDictionary.mjs';
import { updateD3SkosTree } from '../services/vocabulary/skosD3Tree.mjs';
import { sortChildren, createD3Hierarchy, skosD3id } from '../services/vocabulary/d3Utilities.mjs';

export const SkosDictionaryContext = createContext(null);

function SkosDictionaryProvider({ children }) {
    const { authState } = useOktaAuth();
    const [skosEditor, setSkosEditor] = useState({ skosDictionary: null, skosRoot: null });
    const toast = useToast();
    const updateRoot = (dict, root) => {
        setSkosEditor({ skosDictionary: dict, skosRoot: root });
    };

    // Load the SKOS Dictionary from the server
    useEffect(() => {
        if (!authState || !authState.isAuthenticated) return;
        const { accessToken } = authState.accessToken;

        const fetchSkos = async () => {
            const skosDictionary = await createSkosDictionary(accessToken);
            if (skosDictionary !== null) {
                // const skosChart = createD3SkosTree(skosDictionary); // Create a d3 hierarchy for the tree
                const skosChart = updateD3SkosTree(null, skosDictionary); // Create a d3 hierarchy for the tree
                const skosRoot = createD3Hierarchy(skosChart, skosD3id);
                sortChildren(skosRoot); // Sort the children in the tree
                setSkosEditor({
                    skosDictionary,
                    skosRoot,
                });
            } else {
                toast({
                    title: 'Server Error',
                    description: 'SKOS Dictionary failed to load',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };
        fetchSkos();
    }, [authState]);

    const value = useMemo(() => ({
        skosEditor,
        updateRoot,
    }), [skosEditor]);

    return (
        <SkosDictionaryContext.Provider value={value}>
            {children}
        </SkosDictionaryContext.Provider>
    );
}

function useSkosDictionary() {
    const value = useContext(SkosDictionaryContext);
    const { skosEditor } = value;
    return skosEditor.skosDictionary || null;
}

function useSkosRoot() {
    const value = useContext(SkosDictionaryContext);
    const { skosEditor } = value;
    return skosEditor.skosRoot || null;
}

export { SkosDictionaryProvider, useSkosDictionary, useSkosRoot };
