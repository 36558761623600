import React from 'react';
import { createRoot } from 'react-dom/client';
import AppContainer from './AppContainer';

import './style/index.css';
import './style/omcTreeDiagram.css'; // css styling for the OMC and vocabulary tree diagrams

console.log(`Environment: ${process.env.NODE_ENV}`);
console.log(`env file: ${process.env.REACT_APP_ENV}`);
console.log('Pushed: 9/19/2024 - 9:43PST');

// const app = React.createElement(AppContainer);
// ReactDOM.render(app, document.getElementById('root'));

const container = document.getElementById('root');
const root = createRoot(container);
// eslint-disable-next-line react/jsx-filename-extension
root.render(<AppContainer />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
