import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Image,
    SimpleGrid, Center, Spinner
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';

const labkoatStorageUrl = process.env.REACT_APP_LABKOAT_STORAGE_ORCHESTRATION;
// const labkoatStorageUrl = 'http://localhost:3100/storage/get';

async function fetchStorage(accessToken, identifierValue) {
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    const url = `${labkoatStorageUrl}?identifierScope=labkoat&identifierValue=${identifierValue}`;
    const res = await fetch(url, options);
    console.log(url);
    if (res.status === 200) return res.blob(); // Images are base64 encoded
    if (res.status === 401) console.log('Not Authorized'); // User not authorized to view this
    return false; // No image will be displayed
}

async function loadImages(accessToken, stripData) {
    const fetchPromise = stripData.map(async (i) => {
        try {
            const blob = await fetchStorage(accessToken, i.identifierValue);
            return blob
                ? {
                    ...i,
                    img: URL.createObjectURL(blob),
                }
                : false;
        } catch (err) {
            console.log(err);
            return false;
        }
    });
    const allImages = await Promise.all(fetchPromise);
    return allImages.filter((i) => i.img); // Filter the errors from the response
}

const imageStrip = ((images) => {
    const strip = images.map((i) => (
        <Box key={i.identifierValue}>
            <Text as="b">{i.name}</Text>
            <Image src={i.img} />
            {i.description}
        </Box>
    ));
    return (
        <SimpleGrid columns={5} spacing={5}>
            {strip}
        </SimpleGrid>
    );
});

/**
 * Create a strip of images that together create a storyboard
 * @param stripData
 * @param setImgAuthorized
 * @return {JSX.Element}
 * @constructor
 */

function ImageStrip({
    stripData,
    setImgAuthorized,
}) {
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const [images, updateImages] = useState([]);
    const [isLoading, updateLoading] = useState(true);

    useEffect(() => {
        updateLoading(true); // Start the loading indicator
        loadImages(accessToken, stripData)
            .then((imageData) => {
                if (stripData.length === 0 || imageData.length !== 0) {
                    setImgAuthorized(true);
                    updateImages(imageData);
                } else {
                    setImgAuthorized(false); // This came back as unauthorized
                    updateImages([]);
                }
                updateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                updateLoading(false);
            });
    }, [stripData]);

    return isLoading
        ? (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        )
        : (
            <Box margin={5}>
                {imageStrip(images)}
            </Box>
        );
}

ImageStrip.propTypes = {
    stripData: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            img: PropTypes.string,
            identifier: PropTypes.string,
        }),
    ),
    setImgAuthorized: PropTypes.func,
};

ImageStrip.defaultProps = {
    stripData: [],
    setImgAuthorized: () => {
    },
};

export default ImageStrip;
