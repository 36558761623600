import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import SecureRoute from './SecureRoute';

import CustomLogin from './OktaLogin';
import Home from '../../pages/Home';
import Admin from '../../pages/Admin';
import Loading from './Loading';
import Profile from '../../pages/Profile';
import Storyboards from '../../pages/Storyboards';
import Characters from '../../pages/Characters';
import Directory from '../../pages/Directory';
import Approval from '../../pages/Approval';
import OmcTree from '../../pages/OmcTree';
import SkosEditor from '../../pages/SkosEditor';
import OmcJsonEditor from '../../pages/OmcJsonEditor';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/login" element={<CustomLogin/>}/>
            <Route path="login/callback" element={<LoginCallback loadingElement={<Loading/>}/>}/>
            <Route path="/admin" element={<SecureRoute/>}>
                <Route path="/admin" element={<Admin/>}/>
            </Route>
            <Route path="/approval" element={<SecureRoute/>}>
                <Route path="/approval" element={<Approval/>}/>
            </Route>
            <Route path="/storyboard" element={<SecureRoute/>}>
                <Route path="/storyboard" element={<Storyboards/>}/>
            </Route>
            <Route path="/conceptart" element={<SecureRoute/>}>
                <Route path="/conceptart" element={<Characters/>}/>
            </Route>
            <Route path="/directory" element={<SecureRoute/>}>
                <Route path="/directory" element={<Directory/>}/>
            </Route>
            <Route path="/omc" element={<SecureRoute/>}>
                <Route path="/omc" element={<OmcTree/>}/>
            </Route>

            <Route path="/skoseditor" element={<SecureRoute/>}>
                <Route path="/skoseditor" element={<SkosEditor/>}/>
            </Route>

            <Route path="/omcjsoneditor" element={<SecureRoute/>}>
                <Route path="/omcjsoneditor" element={<OmcJsonEditor/>}/>
            </Route>
            <Route path="/profile" element={<SecureRoute/>}>
                <Route path="/profile" element={<Profile/>}/>
            </Route>
        </Routes>
    );
}

export default AppRoutes;
