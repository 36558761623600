import React, { useState } from 'react';

import {
    Formik, Form, Field,
} from 'formik';
import {
    Box,
    Center,
    Flex,
    Select,
    Input,
    Button,
    IconButton,
    FormErrorMessage, FormControl, FormLabel,
} from '@chakra-ui/react';
import { UpDownIcon } from '@chakra-ui/icons';

import { nodeParams } from './omcDefaults.mjs';

function ProjectSelect({
    projects,
    currentProject,
    handleProject,
}) {
    const pNames = Object.keys(projects); // Each of the projects that can be selected
    const options = pNames.map((v) => (
        <option value={v} key={v}>{projects[v].label}</option>
    ));
    return (
        <Box w="100%">
            <FormLabel>Project</FormLabel>
            <Select value={currentProject} onChange={handleProject} name="projectName">
                {options}
            </Select>
        </Box>
    );
}

function IdentifierQuery({
    identifierScope,
    resetRoot,
    currentProject,
}) {
    return (
        <Box>
            <Formik
                enableReinitialize
                initialValues={{
                    identifierScope,
                    identifierValue: '',
                }}
                validate={(values) => {
                    const error = {};
                    if (!values.identifierValue) {
                        error.identifierValue = 'Required';
                    }
                    if (!values.identifierScope) {
                        error.identifierScope = 'Required';
                    }
                    return error;
                }}
                onSubmit={async (values, {
                    setSubmitting,
                    setErrors,
                }) => {
                    console.log('Submit');
                    console.log(values);
                    // Reset the root node using the identifier
                    const valid = await resetRoot({
                        currentProject,
                        identifierScope: values.identifierScope.trim(),
                        identifierValue: values.identifierValue.trim(),
                    });
                    if (!valid) {
                        console.log('Error loading');
                        setSubmitting(false);
                        setErrors({ identifierValue: 'Identifier not found' });
                    }
                }}
            >
                {() => (
                    <Form>
                        <Flex>
                            <Box w="30%" pl={2} pr={2}>
                                <FormLabel>Identifier Scope</FormLabel>
                                <Field name="identifierScope">
                                    {({
                                        field,
                                        form,
                                    }) => (
                                        <FormControl
                                            isInvalid={form.errors.identifierScope && form.touched.identifierScope}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Identifer Scope"
                                                {...field}
                                            />
                                            <FormErrorMessage>{form.errors.identifierScope}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </Box>
                            <Box w="40%" pl={2} pr={2}>
                                <FormLabel>Identifier Value</FormLabel>
                                <Field name="identifierValue">
                                    {({
                                        field,
                                        form,
                                    }) => (
                                        <FormControl
                                            isInvalid={form.errors.identifierValue && form.touched.identifierValue}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Identifer Value"
                                                {...field}
                                            />
                                            <FormErrorMessage>{form.errors.identifierValue}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </Box>
                            <Box w="33%" pl={2} pr={2} alignSelf="flex-end">
                                <Button
                                    colorScheme="teal"
                                    type="submit"
                                    float="right"
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}

function PropertyQuery({
    entities,
    handleProjectChange,
}) {
    return (
        <Box>
            <Formik
                initialValues={{
                    entityType: '',
                    propertyName: '',
                    propertyValue: '',
                }}
                validate={(values) => {
                    const error = {};
                    if (!values.identifierValue) {
                        error.identifierValue = 'Required';
                        console.log('Validation Error');
                        console.log(values);
                    }
                    return error;
                }}
                onSubmit={(values) => {
                    console.log('Submit');
                    console.log(values);
                    // Reset the root node using the identifier
                    // resetRoot({
                    //     identifierScope: values.identifierScope.trim(),
                    //     identifierValue: values.identifierValue.trim(),
                    // });
                }}
            >
                {() => (
                    <Form>
                        <Flex>
                            <Box w="25%" pl={2} pr={2}>
                                <FormLabel>Entity Type</FormLabel>
                                <Field name="entityType">
                                    {({
                                        field,
                                        form,
                                    }) => (
                                        <Select {...field}>
                                            {entities}
                                        </Select>
                                    )}
                                </Field>
                            </Box>
                            <Box w="25%" pl={2} pr={2}>
                                <FormLabel>Property Name</FormLabel>
                                <Field name="propertyType">
                                    {({
                                        field,
                                        form,
                                    }) => (
                                        <Select {...field}>
                                            <option value="name">name</option>
                                            <option value="description">description</option>
                                        </Select>
                                    )}
                                </Field>
                            </Box>
                            <Box w="25%" pl={2} pr={2}>
                                <FormLabel>Property Value</FormLabel>
                                <Field name="propertyValue">
                                    {({
                                        field,
                                        form,
                                    }) => (
                                        <FormControl
                                            isInvalid={form.errors.propertyValue && form.touched.propertyValue}
                                        >
                                            <Input
                                                type="text"
                                                placeholder="Query"
                                                {...field}
                                            />
                                            <FormErrorMessage>{form.errors.propertyValue}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </Box>
                            <Box w="25%" pl={2} pr={2} alignSelf="flex-end">
                                <Button
                                    colorScheme="teal"
                                    type="submit"
                                    float="right"
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </Box>
    );
}

function OmcTreeQueryBar({
    currentProject,
    projects,
    handleProjectChange,
    resetRoot,
}) {
    const [queryType, setQueryType] = useState('identifier');
    const entities = Object.keys(nodeParams)
        .filter((e) => (nodeParams[e].query.allow))
        .map((e) => (
            <option value={e} key={e}>{e}</option>
        ));

    function handleQueryChange(e) {
        const newQueryType = queryType === 'identifier' ? 'property' : 'identifier';
        console.log(`Change the Query to ${newQueryType}`);
        setQueryType(newQueryType);
    }

    return (
        <Box>
            <Flex m={2}>
                <Box w="20%" pl={2} pr={2}>
                    <ProjectSelect
                        currentProject={currentProject}
                        handleProject={(e) => handleProjectChange(e)}
                        projects={projects}
                    />
                </Box>
                <Box w="80%">
                    <Flex>
                        <Center>
                            <IconButton
                                w="5%"
                                alignSelf="flex-end"
                                aria-label="Select Query"
                                icon={<UpDownIcon />}
                                onClick={(e) => handleQueryChange(e)}
                            />
                        </Center>
                        <Box w="95%">
                            {queryType === 'identifier'
                                ? (
                                    <IdentifierQuery
                                        currentProject={currentProject}
                                        identifierScope={projects[currentProject].identifierScope}
                                        resetRoot={resetRoot}
                                    />
                                )
                                : (
                                    <PropertyQuery
                                        entities={entities}
                                    />
                                )}
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
}

export default OmcTreeQueryBar;
