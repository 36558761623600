/**
 * Handle data manipulation for the vocabulary tree
 */

import { expandChildren } from './d3Utilities.mjs';

/**
 * Return the scheme identifiers for this branch of vocabulary tree
 */

export function schemeBranchId(node) {
    return node.data.type !== 'skos:ConceptScheme'
        ? schemeBranchId(node.parent)
        : node.data.id;
}

/**
 * Create a hierarchy for the vocabulary tree
 * @param openChildren {object} -
 * @param skosDictionary {object} -
 * @return {{data: {}, children: *, label: string}}
 */

export function updateD3SkosTree(openChildren, skosDictionary) {
    const currentChildren = openChildren ? openChildren : { 'skos-Root': true }; // Default to open the root node
    return expandChildren('skos-Root', currentChildren, skosDictionary)
}