import { useEffect } from 'react';
import { select } from 'd3-selection';
import { tree, hierarchy } from 'd3-hierarchy';
import { linkHorizontal } from 'd3-shape';

import omcTree from '../../../services/omcTree.mjs';
import fetchOmc from '../../../services/fetchOmc.mjs';

import { nodeParams, fontHeight, nodeSpaceVertical } from './omcDefaults.mjs';
import { node } from 'prop-types';

// import './hierarchyTree.css';

// import { propTypes, chartDefaults } from '../config';

function OmcTreeDiagram(
    {
        accessToken,
        chartData,
        projects,
        currentProject,
        setSideBar,
        containerDimensions,
        treeId,
    },
) {
    // console.log('Render OmcTreeDiagram');
    const { containerWidth } = containerDimensions;

    // Return shape parameter for a given node type
    const nodeShape = ((nodeType) => {
        const { shape } = nodeParams[nodeType] ? nodeParams[nodeType].style : nodeParams.Default.style;
        return shape;
    });

    const nodeIcon = ((nodeType) => {
        const { icon } = nodeParams[nodeType] ? nodeParams[nodeType].style : nodeParams.Default.style;
        return icon || '';
    });

    // Return the label for a given node type
    const nodeLabel = ((nodeType, d) => {
        const { label } = nodeParams[nodeType] ? nodeParams[nodeType] : nodeParams.Default;
        return label || (d.data.omc.name || 'Blank');
    });

    const nodeColor = ((nodeType) => {
        const { color } = nodeParams[nodeType] ? nodeParams[nodeType].style : nodeParams.Default.style;
        return color;
    });

    const nodeProperty = ((nodeType, d) => {
        const { displayProperty } = nodeParams[nodeType] ? nodeParams[nodeType] : nodeParams.Default;
        return d.data.omc[displayProperty[0]] || 'Blank';
    });

    const diagonal = linkHorizontal()
        .x((d) => d.y)
        .y((d) => d.x);

    // Offset a links coordinates based on the type of node it is exiting
    const exitOffsetLink = ((source) => {
        const shape = nodeShape(source.data.entityType);
        return {
            x: source.x,
            y: source.y + shape.width / 2,
        };
    });

    const entryOffsetLink = ((source) => {
        const shape = nodeShape(source.data.entityType);
        return {
            x: source.x,
            y: source.y - shape.width / 2,
        };
    });

    // Fetch an entity from the database with its Contexts
    const dynamicFetch = (async (d) => {
        const { omc } = d.data;
        const { identifierScope } = projects[currentProject];
        const newNode = await fetchOmc(omc, identifierScope, currentProject, accessToken);
        const node = await omcTree(newNode, identifierScope);
        return node;
    });

    const treeHeight = ((node, nHeight = 0) => {
        let h = nHeight + 1;
        if (node.children) {
            const branchHeight = node.children.map((c) => treeHeight(c, h));
            const maxHeight = Math.max(...branchHeight);
            h = maxHeight > h ? maxHeight : h;
        }
        return h;
    });

    // Recalculate the new depth of the tree
    const depthTree = ((parent, node, depth) => {
        node.depth = depth;
        node.parent = parent;
        if (node.children) {
            node.children.forEach((child) => depthTree(node, child, depth + 1));
        }
        // return height;
    });

    // After loading a new node, insert it into the hierarchy
    const insertNode = (parent, node) => {
        const newHierarchy = hierarchy(node);
        parent.children = newHierarchy.children; // Add the new branch to the existing node;
        depthTree(parent.parent, parent, parent.depth);
    };

    useEffect(() => {
        if (!chartData) return;
        const root = hierarchy(chartData);
        console.log(root);

        // Specify the charts’ dimensions. The height is variable, depending on the layout.
        // Rows are separated by dx pixels, columns by dy pixels. These names can be counter-intuitive
        // (dx is a height, and dy a width). This because the tree must be viewed with the root at the
        // “bottom”, in the data domain. The width of a column is based on the tree’s height.
        const treeWidth = ((th) => containerWidth / th); // Horizontal spacing between nodes
        let nodeSpaceHorizontal = treeWidth(treeHeight(root)); // Height of the tree by width of container to adjust horizontal space

        console.log(`Value for dy: ${nodeSpaceHorizontal}`);
        console.log(`Value for dx: ${nodeSpaceVertical}`);

        // Define the tree layout and the shape for links.
        let d3Tree = tree()
            .nodeSize([nodeSpaceVertical, nodeSpaceHorizontal]);

        // Select the main container using the tree's id
        const svg = select(`#${treeId}-container`); // The g that zoom is on.

        // Select the g container for the graphs nodes
        const gNode = select(`#${treeId}-container`)
            .select('g.treeNode')
            .attr('cursor', 'pointer')
            .attr('pointer-events', 'all');

        // Select the g container for the graphs links
        const gLink = select(`#${treeId}-container`)
            .select('g.treeLink')
            .attr('fill', 'none')
            .attr('stroke', '#555')
            .attr('stroke-opacity', 0.5)
            .attr('stroke-width', 1.5);

        // Handle a node being clicked and the tree expansion and contraction
        const nodeClicked = (async (e, d) => {
            if (!d.children && !d._children) { // If no children, check the database
                console.log(`Load data: ${d.data.name}`);
                const branchNode = await dynamicFetch(d); // Try to load any branch nodes
                if (branchNode === null) return;
                insertNode(d, branchNode);
            } else {
                if (d.children) {
                    const descendants = d.descendants();
                    descendants.forEach((des) => {
                        if (des.children) des._children = des.children; // Only collapse if not already done so.
                        des.children = null;
                    });
                } else {
                    d.children = d._children;
                }
            }

            // Adjust horizontal spacing of nodes
            nodeSpaceHorizontal = treeWidth(treeHeight(root)) > 200 ? treeWidth(treeHeight(root)) : 200;
            d3Tree = tree() // Define the tree layout and the shape for links.
                .nodeSize([nodeSpaceVertical, nodeSpaceHorizontal]);
        });

        function update(event, source) {
            const duration = event?.altKey ? 2500 : 250; // Hold the alt key to slow down the transition

            const nodes = root.descendants();
            const links = root.links();

            const omc = nodes.map((n) => n.data.omc);
            console.log(omc);

            d3Tree(root); // Compute the new tree layout.

            const transition = svg.transition()
                .duration(duration)
                .tween('resize', window.ResizeObserver ? null : () => () => svg.dispatch('toggle'));

            const node = gNode.selectAll('g')
                .data(nodes, (d) => d.data.nodeId);
            // // Update the nodes…
            // const node = gNode.selectAll('g')
            //     .data(nodes, (d) => d.data.nodeId)
            //     .join(
            //         (enter) => {
            //             return enter.append('g')
            //                 .attr('class', (d) => (d.data.entityType === 'Context' ? 'cxt-node' : 'ent-node'))
            //                 .attr('transform', (d) => `translate(${source.y0},${source.x0})`);
            //         },
            //         (update) => {
            //             return update;
            //         },
            //     )
            //
            // node.each((p, j) => {
            //     const type = select(this)
            //         .append('circle');
            //     console.log(p);
            //     console.log(j);
            // });
            // Transition exiting nodes to the parent's new position.
            node.exit()
                .transition(transition)
                .duration(duration)
                .remove()
                .attr('transform', () => {
                    const s = exitOffsetLink({
                        x: source.x,
                        y: source.y,
                        data: { entityType: source.data.entityType },
                    });
                    return `translate(${s.y}, ${s.x})`;
                })
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 0);

            // Enter any new nodes at the parent's previous position.
            const nodeEnter = node.enter()
                .append('g')
                .attr('class', (d) => (d.data.entityType === 'Context' ? 'cxt-node' : 'ent-node'))
                .attr('transform', (d) => {
                    if (!d.parent) { // A root node (no parent) has no node it emanates from
                        const shape = nodeShape(d.data.entityType);
                        return `translate(${d.y - shape.width / 2}, ${d.x - shape.height / 2})`;
                    }
                    const s = exitOffsetLink({ // Spawn new nodes at link exit point
                        x: source.x0 || source.x,
                        y: source.y0 || source.y,
                        data: { entityType: source.data.entityType },
                    });
                    return `translate(${s.y}, ${s.x})`;
                })
                .attr('fill-opacity', 0)
                .attr('stroke-opacity', 1)
                .on('mouseover', (e, d) => {
                    setSideBar(d.data.omc);
                })
                .on('click', async (e, d) => {
                    await nodeClicked(e, d);
                    update(e, d);
                });

            // Transition nodes to their new position.
            node.merge(nodeEnter)
                .transition(transition)
                .duration(duration)
                .attr('transform', (d) => {
                    const shape = nodeShape(d.data.entityType);
                    return `translate(${d.y - shape.width / 2}, ${d.x - shape.height / 2})`;
                })
                .attr('fill-opacity', 1)
                .attr('stroke-opacity', 1);

            nodeEnter.append('rect')
                .attr('width', (d) => nodeShape(d.data.entityType).width)
                .attr('height', (d) => nodeShape(d.data.entityType).height)
                .attr('ry', (d) => nodeShape(d.data.entityType).radius)
                .attr('rx', (d) => nodeShape(d.data.entityType).radius)
                .attr('fill', (d) => (nodeColor(d.data.entityType)))
                .attr('stroke-width', 1);

            // nodeEnter.append('path')
            //     .attr('d', (d) => (nodeIcon(d.data.entityType)))
            //     .attr('transform', 'translate(0 0) scale(0.5)');

            nodeEnter.append('text')
                .attr('dx', (d) => (nodeShape(d.data.entityType).width) / 2)
                .attr('dy', (d) => -(nodeShape(d.data.entityType).height / 4))
                .attr('y', (d) => (nodeShape(d.data.entityType).height / 2) + fontHeight / 2)
                .attr('text-anchor', 'middle')
                .text((d) => nodeLabel(d.data.entityType, d));

            nodeEnter.append('text')
                .attr('dx', (d) => (nodeShape(d.data.entityType).width) / 2)
                .attr('dy', (d) => (nodeShape(d.data.entityType).height / 4))
                .attr('y', (d) => (nodeShape(d.data.entityType).height / 2) + fontHeight / 2)
                .attr('text-anchor', 'middle')
                .text((d) => nodeProperty(d.data.entityType, d));

            nodeEnter.append('text')
                .attr('dx', -5)
                .attr('y', (d) => (nodeShape(d.data.entityType).height) * 0.4)
                .attr('text-anchor', 'end')
                .text((d) => d.data.edge || null);

            // Update the links…
            const link = gLink.selectAll('path')
                .data(links, (d) => `${d.source.data.nodeId}-${d.target.data.nodeId}`); // return d.target.id

            // Enter any new links at the parent's previous position.
            const linkEnter = link.enter()
                .append('path')
                .attr('id', (d) => `${d.source.data.nodeId}-${d.target.data.nodeId}`)
                .attr('d', () => {
                    const s = exitOffsetLink({
                        x: source.x0,
                        y: source.y0,
                        data: { entityType: source.data.entityType },
                    });
                    return diagonal({
                        source: s,
                        target: s,
                    });
                });

            // Transition links to their new position.
            link.merge(linkEnter)
                .transition(transition)
                .duration(duration)
                .attr('d', (d) => diagonal({
                    source: exitOffsetLink(d.source),
                    target: entryOffsetLink(d.target),
                }));

            // Transition exiting nodes to the position of the clicked node that is closing.
            link.exit()
                .transition(transition)
                .duration(duration)
                .remove()
                .attr('d', () => diagonal({
                    source: exitOffsetLink(source),
                    target: exitOffsetLink(source),
                }));

            // Stash the old positions for transition.
            root.eachBefore((d) => {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        }

        // Do the first update to the initial configuration of the tree — where a number of nodes
        // are open (arbitrarily selected as the root, plus nodes with 7 letters).
        root.descendants()
            .forEach((d, i) => {
                // d.id = i;
                // d._children = d.children;
                // if (d.depth && d.data.name.length !== 7) d.children = null;
            });

        update(null, root);
    }, [chartData]);

    return null;
}

export default OmcTreeDiagram;
