import React, { useEffect, useState } from 'react';
import {
    Box,
    Center,
    Spinner, Alert, AlertIcon,
} from '@chakra-ui/react';
import { useOktaAuth } from '@okta/okta-react';

import ImageStrip from '../Components/Layout/Utilities/ImageStrip';
import ImageGroupButtons from '../Components/Layout/Utilities/ImageGroupButtons';

import { fetchAssetInfo } from '../services/assetUtilities';

async function fetchOMC(accessToken) {
    return fetchAssetInfo(accessToken, 'artwork.storyboard');
}

function Storyboards() {
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();

    const [imgAuthorized, setImgAuthorized] = useState(true);
    const [stripData, setStripData] = useState([]);
    const [assetData, updateAssetData] = useState([]);
    const [isLoading, updateLoading] = useState(true);

    useEffect(() => {
        fetchOMC(accessToken)
            .then((data) => {
                updateAssetData(data);
                updateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                updateLoading(false);
            });
    }, []);

    const notAuthorized = imgAuthorized
        ? (
            <Box />
        )
        : (
            <Alert status="error">
                <AlertIcon />
                You are not authorized to view content on this page
            </Alert>
        );

    return isLoading
        ? (
            <Center>
                <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading" />
            </Center>
        )
        : (
            <Box m="3">
                <ImageGroupButtons
                    assetData={assetData}
                    setStripData={setStripData}
                    updateLoading={updateLoading}
                />
                <ImageStrip
                    stripData={stripData}
                    setImgAuthorized={setImgAuthorized}
                />
                {notAuthorized}
            </Box>
        );
}

export default Storyboards;
