/**
 * Utilities to help manipulate data for OMC JSON D3 Tree
 */

import { expandChildren } from './d3Utilities.mjs';

/**
 * Create the full tree from the Root node
 * @param openChildren
 * @param omcDict
 * @returns {{children: {children, definition: string, id: string, label: string, type: string}[], definition: string, id: string, label: string, type: string}}
 */

export function updateOmcJsonD3Tree(openChildren, omcDict) {
    const currentChildren = openChildren ? openChildren : { 'omc_00001': true }; // Default to open the root node
    return expandChildren('omc:00001', currentChildren, omcDict); // Root node is 'd3Root', build the whole tree
}
