import React from 'react';

import {
    Box,
    Menu,
    MenuItem, MenuList,
    Portal,
} from '@chakra-ui/react';

function ContextMenu({ contextMenu }) {
    if (!contextMenu || !contextMenu.items) return null;
    const {
        items, position, onClose, onClick, node,
    } = contextMenu;

    return (
        <Portal>
            <Box
                style={{
                    position: 'absolute',
                    top: position.y,
                    left: position.x - 20,
                    backgroundColor: 'red',
                    boxShadow: '0px 0px 15px rgba(0,0,0,0.3)',
                    zIndex: 1000,
                }}
                onMouseLeave={onClose}
            >
                <Menu isOpen={true}>
                    <MenuList p={1} m={1}>
                        {items.map((item) => (
                            <MenuItem
                                fontSize={14}
                                key={item.key}
                                onClick={() => {
                                    onClick(item.action, node);
                                    onClose();
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
        </Portal>
    );
}

export default ContextMenu;
