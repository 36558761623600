import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Box } from '@chakra-ui/react';

export default function Logo() {
    return (
        <Box boxSize="50px">
            <Link to="/">
                <Image src="/images/lab-coat.png" alt="LabKoat" />
            </Link>
        </Box>
    );
}
