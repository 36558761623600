import { Button, Stack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

function ImageGroupButtons({
    assetData,
    setStripData,
}) {
    const updateStrip = (stripData) => {
        setStripData(stripData);
    };

    const buttons = assetData.map((b) => (
        <Button
            onClick={() => updateStrip(b.Asset, b.name)}
            key={b.name}
        >
            {b.name}
        </Button>
    ));
    return (
        <Stack direction="row">
            {buttons}
        </Stack>
    );
}

ImageGroupButtons.propTypes = {
    assetData: PropTypes.arrayOf(
        PropTypes.shape({
            identifierValue: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            Asset: PropTypes.array,
        }),
    ),
    setStripData: PropTypes.func,
};

ImageGroupButtons.defaultProps = {
    assetData: [],
    setStripData: () => {},
};

export default ImageGroupButtons;
