/**
 * Maintain an in memory cache of the SKOS vocabulary for the UI to use
 *
 * Provide functions to update and query the cache
 */

import FuzzySearch from 'fuzzy-search';

import { getDictionary, postUpdate } from './api.mjs';
import { getNode, getType, getRelated, cacheUpdate, errorResponse } from './dictionaryUtilities.mjs';

const makeArray = (val) => (Array.isArray(val) ? val : [val]);

/**
 * Generate a new id for a SKOS entity, based on the highest existing id
 *
 * @param skosType {string} - The type of SKOS entity to generate an id for
 * @param offset {number} - The offset to add to the highest existing id (when creating multiple node before committing)
 * @return {string}
 */
function generateId(skosType = 'concept', offset = 1) {

    const higherId = ((type) => {
        const nodeList = Object.keys(this.nodes);
        const idList = nodeList.filter((id) => this.nodes[id].type === type)
            .sort(); // Sort id's high to low
        let highStr = idList.pop(); // Take the highest result
        highStr = highStr.substring(highStr.lastIndexOf('-') + 1); // Strip the hex number in the id
        const highId = parseInt(Number(`0x${highStr}`), 10); // Convert hex to decimal
        return `-${(highId + offset).toString(16)
            .padStart(6, '0')}`; // Increment the id
    });

    if (skosType === 'concept') return `vmc:c${higherId('skos:Concept')}`;
    if (skosType === 'scheme') return `vmc:c${higherId('skos:ConceptScheme')}`;
    if (skosType.includes('label')) return `vmc:xl-en${higherId('skosxl:Label')}`;
}

/**
 * Make updates to the Neo4J database then internal cache
 * @param action
 * @param accessToken {string}
 */

async function update(action, accessToken) {
    const apiUpdate = await postUpdate(action, 'skos', accessToken); // Update the database
    cacheUpdate.call(this, action) // Update the internal cache
    // return apiUpdate;
    return true;
}

/**
 * Return a complete record for the requested skos:Concept
 * @param skosId
 * @returns {{[p: string]: *} | null}
 */
function skosConcept(skosId) {
    const baseNode = this.getNode(skosId);
    if (!baseNode || baseNode.type !== 'skos:Concept') return null;
    const prefLabel = this.getRelated(skosId, 'prefLabel');
    const altLabel = this.getRelated(skosId, 'altLabel');
    const inScheme = this.getRelated(skosId, 'inScheme');
    return {
        ...baseNode,
        prefLabel: this.getNode(prefLabel[0]) || null,
        altLabel: altLabel.map((nId) => this.getNode(nId)),
        inScheme: inScheme.map((nId) => this.getNode(nId)),
    }
}

/**
 * Return a complete record for the requested skos:Concept
 * @param skosId
 * @returns {{[p: string]: *} | null}
 */
function skosConceptScheme(skosId) {
    const baseNode = this.getNode(skosId);
    if (!baseNode || (baseNode.type !== 'skos:ConceptScheme' && baseNode.type !== 'omc:Root')) return null;
    return {
        ...baseNode,
        prefLabel: {
            value: baseNode.prefLabel,
            language: 'en',
        },
    }
}

function getEntity(skosId) {
    const baseNode = this.getNode(skosId);
    if (!baseNode) return null;
    let entity
    switch (baseNode.type) {
        case 'skos:Concept':
            entity = this.skosConcept(skosId);
            break;
        case 'skos:ConceptScheme':
            entity = this.skosConceptScheme(skosId);
            break;
        case 'omc:Root':
            entity = this.skosConceptScheme(skosId);
            break;
        default:
            entity = null;
    }
    return entity;
}

/**
 * Return identifiers of child properties one level deep for a given entity.
 *
 * @param skosId {string} - The identifier for the requested entity
 * @returns {string[]}
 */

const childMap = {
    'omc:Root': ['hasScheme'],
    'skos:ConceptScheme': ['hasTopConcept'],
    'skos:Concept': ['narrower'],
    'skosxl:Label': [],
}

function childProperties(omcId) {
    const entity = this.getNode(omcId);
    const relations = childMap[entity.type];
    return this.edges[omcId].filter((n) => relations.includes(n.relation))
        .map((n) => n.targetId);
}

/**
 * Return identifiers of all child properties, following all branches to their leaves.
 *
 * @param skosId {string} - The identifier for the requested entity
 * @returns {string[]}
 */
function allChildProperties(skosId) {
    const children = this.childProperties(skosId);
    return [...children, ...children.flatMap((id) => this.allChildProperties(id))]
}

/**
 * Return identifiers for parent properties, based on hierarchical view of graph
 *
 * @param skosId {string} - The identifier of thw requested node
 * @returns {string[]}
 */
function parentProperties(skosId) {
    return this.edges[skosId].filter((n) => n.relation === 'topConceptFor' || n.relation === 'broader')
        .map((n) => n.targetId);
}

/**
 * Return the identifiers for all Concepts that are in the requested schemes, or those not in any scheme
 *
 * @param schemeId {string[]||string||null } - The scheme identifiers to get the concepts for, null for no scheme
 * @return {string[]}
 */
function schemeHasConcept(schemeId) {
    const allConcepts = this.getType('skos:Concept')
    if (!schemeId) { // Return concepts that do not have a scheme
        return allConcepts.reduce((arr, nId) => {
            const ns = this.edges[nId].filter((e) => e.relation === 'inScheme');
            return ns.length ? arr : [...arr, nId];
        }, []);
    } else { // Return all the edges that are in the requested schemes
        const s = makeArray(schemeId);
        return allConcepts.reduce((arr, key) => {
            const ns = this.edges[key].filter((e) => (e.relation === 'inScheme' && s.includes(e.targetId)));
            return [...arr, ...ns.map((n) => n.sourceId)];
        }, []);
    }
}

/**
 * Return the identifiers for the Schemes that the given Concept is in
 *
 * @param skosId {String} - The skos id of the Concept to be checked
 * @return {string[]}
 */

function conceptInScheme(skosId) {
    return this.edges[skosId].filter((e) => e.relation === 'inScheme')
        .map((e) => e.targetId);
}

/**
 * Return the scheme identifiers for any schemes this Concept is a topConcept of
 *
 * @param skosId {String} - The skos id of the Concept to be checked
 * @param schemeId {String[]} - A list of schemes to check if this Concept is a TopConcept of
 * @returns {String[]} - The Scheme ids that this Concept is a TopConcept of
 */

function conceptIsTopConcept(skosId, schemeId) {
    return schemeId.filter((sId) => (this.edges[sId].filter((e) => e.relation === 'hasTopConcept' && e.targetId === skosId)).length);
}

/**
 * Return all the identifiers for all narrower Concepts for a given Concept
 *
 * @param skosId {String} - The skos id of the Concept to be checked
 * @param narrower{String[]}
 * @returns {String[]} - All narrower concepts
 */

function conceptAllNarrower(skosId, narrower = []) {
    const narrow = this.getRelated(skosId, 'narrower')
    narrow.forEach((nId) => narrower.push(...this.conceptAllNarrower(nId, narrower)));
    return [...narrower, ...narrow];
}

// Checks which scheme a concept should be removed from, as well as whether narrower concepts should be removed
// Something is removed if it is in the scheme, but not a topConcept of another scheme
// To avoid removing a Concept from a separate tree, only pass the schemes of the broader Concept
function conceptSchemeRemoval(skosId, removalSchemes) {
    // Checks if a Concept is a TopConcept of any of the schemes
    // Returns on the scheme Id's for for the schemes a Concept is not a TopConcept of
    const chkTcSchemeRemoval = ((skosId, sId) => {
        const topConcept = this.conceptIsTopConcept(skosId, sId); // Is this a topConcept in scheme
        return sId.filter((s) => !topConcept.includes(s)); // Return any schemes this is not a TC for
    });

    const removeFromSchemes = chkTcSchemeRemoval(skosId, removalSchemes); // Schemes this node is not a topConcept of
    const deletion = removeFromSchemes.map((sId) => deleteAction(skosId, sId, 'inScheme'));
    const narrowerConcepts = this.getRelated(skosId, 'narrower'); // Repeat for narrower concepts
    narrowerConcepts.forEach((nId) => {
        deletion.push(...this.conceptSchemeRemoval(nId, removeFromSchemes));
    });
    return deletion;
}

// Remove the narrower/broader chain for Concepts unless they are used in another scheme
function removeNarrower(skosId, sId) {
    const allNarrower = this.conceptAllNarrower(skosId);
    const deletion = [];
    allNarrower.forEach((nId) => {
        const cScheme = this.conceptInScheme(nId); // Which schemes is the concept already in
        const remainingSchemes = cScheme.filter((s) => s !== sId); // Is this concept in other schemes
        if (!remainingSchemes.length) {
            deletion.push(deleteAction(skosId, nId, 'narrower'));
            deletion.push(deleteAction(nId, skosId, 'broader'));
        }
    });
    return deletion;
}

// Tests if a label with the same value and language already exists
// Returns the id if already exists, or the new label node if it does not exist
function labelEquality(label) {
    const { nodes } = this;
    const skosLabels = Object.keys(nodes)
        .filter((skosId) => nodes[skosId].type === 'skosxl:Label'
            && nodes[skosId].language === label.language
            && nodes[skosId].value === label.value);
    return skosLabels.length ? this.getNode(skosLabels[0]) : label;
}

function labelExists(label) {
    const skosLabels = this.getType('skosxl:Label');
    const exists = skosLabels.filter((skosId) => {
        const l = this.getNode(skosId);
        return label.language === l.language && label.value === l.value;
    })
    return exists.length;
}

// Update a string value in a SKOS entity
function stringUpdate(rowData, field, newValue) {
    const { id } = rowData; // Node id
    const oldNode = this.nodes[id];
    const newNode = { ...oldNode };
    newNode[field] = newValue;
    const action = { // Create will update the node
        update: [newNode],
    };

    return {
        error: null,
        action,
    };
}

// Update a label value in a SKOS entity, may involve adding or removing entities.
function altLabelUpdate(rowData, field, newValue) {
    const { id } = rowData; // Node id
    const oldNode = this.nodes[id];
    const oldAltLabelId = rowData.altLabel.map((n) => n.id); // Existing alt labels
    const action = {
        create: [],
        update: [],
        delete: [],
    };
    let offset = 0;

    const labelState = newValue.map((label) => labelEquality.call(this, label)); // Existing or new labels
    const newAltLabelId = labelState.filter((label) => label.id)
        .map((label) => label.id); // The new alt labels identifiers

    labelState.forEach((label) => {
        // A new edge from the node to the existing label if it does not exist
        if (label.id && !oldAltLabelId.includes(label.id)) {
            action.create.push({
                sourceId: id,
                sourceType: 'Concept',
                targetId: label.id,
                targetType: 'Label',
                relation: 'altLabel',
            });
        }
        // A new edge and a new node if the label is new
        if (!label.id && !oldAltLabelId.includes(label.id)) {
            const labelId = this.generateId('label-en', offset += 1);
            action.create.push({
                ...label,
                id: labelId,
                type: 'skosxl:Label',
            });
            action.create.push({
                sourceId: id,
                sourceType: 'Concept',
                targetId: labelId,
                targetType: 'Label',
                relation: 'altLabel',
            });
        }
    });

    // Check for any altLabels that were removed and delete the edge
    oldAltLabelId.forEach((labelId) => {
        if (!newAltLabelId.includes(labelId)) {
            action.delete.push({
                sourceId: id,
                sourceType: 'Concept',
                targetId: labelId,
                targetType: 'Label',
                relation: 'altLabel',
            });
        }
    });

    const newNode = { ...oldNode };
    newNode[field] = newValue;
    return {
        error: null,
        action,
    };
}

function prefLabelUpdate(rowData, field, newValue) {
    const { prefLabel } = rowData;
    const updatedPrefLabel = {
        ...prefLabel,
        value: newValue,
    }
    const exists = labelExists.call(this, updatedPrefLabel);
    if (exists) return this.errorResponse(`A concept with this label already exists`)

    const updatedConcept = {
        ...rowData,
        prefLabel: newValue,
    }

    return {
        error: null,
        action: {
            create: [],
            update: [updatedConcept, updatedPrefLabel],
            delete: [],
        },
    };
}


const deleteAction = ((sourceId, targetId, relation) => ({
    sourceId,
    targetId,
    relation,
}));

function createAction(sourceId, targetId, relation) {
    const sourceNode = this.getNode(sourceId);
    const sourceType = sourceNode.type.replace('skos:', '');
    const targetNode = this.getNode(targetId);
    const targetType = targetNode.type.replace('skos:', '');
    return {
        sourceId,
        sourceType,
        targetId,
        targetType,
        relation,
    };
}

/**
 * Add a new Concept node to the SKOS vocabulary
 * @param newData
 * @return {Promise<{error: null, value: string}>}
 */

function conceptAddTerm({
    id = null,
    prefLabel = null,
    status = null,
    definition = null,
    altLabel,
    inScheme,
    ...rest
}) {
    // Must have valid entries for prefLabel, status and definition
    const hasRequired = ((prefLabel.value || prefLabel.value !== '') && status && definition !== '');
    if (!hasRequired) return this.errorResponse('Must contain entry for: Term, Status & Definition');

    let offset = 1;
    const newId = this.generateId('concept', offset);
    // Create new labels, or use existing ones;
    const create = [];
    // const newPrefLabel = labelEquality.call(this, prefLabel);
    const termExists = labelExists.call(this, prefLabel);
    if (termExists) return this.errorResponse(`A concept with this label already exists`)
    const newPrefLabel = prefLabel;

    const newAltLabel = altLabel.map((label) => labelEquality.call(this, label));
    // Does a new prefLabel need to be created
    if (!newPrefLabel.id) {
        prefLabel.id = this.generateId('label-en', offset += 1);
        prefLabel.type = 'skosxl:Label';
        create.push(prefLabel);
    }
    create.push({
        sourceId: newId,
        sourceType: 'Concept',
        targetId: prefLabel.id,
        targetType: 'Label',
        relation: 'prefLabel',
    });
    // Create new altLabels and the edges to the Concept
    newAltLabel.forEach((altLabel) => {
        altLabel.id = this.generateId('label-en', offset += 1);
        altLabel.type = 'skosxl:Label';
        create.push(altLabel);
    });
    newAltLabel.forEach((label) => {
        create.push({
            sourceId: newId,
            sourceType: 'Concept',
            targetId: label.id,
            targetType: 'Label',
            relation: 'altLabel',
        });
    });

// Add the new Concept
    create.push({
        ...rest,
        id: newId,
        prefLabel: prefLabel.value,
        status,
        definition,
    });

    return {
        error: null,
        action: { create },
    };
}

/**
 * Update a field in a Concept node
 * @param oldData
 * @param newData
 * @return {Promise<{error: null, value: *}|{error: string, value: *}>}
 */

function conceptFieldChange({
    oldData,
    newData,
}) {
    const updatePattern = {
        status: stringUpdate,
        definition: stringUpdate,
        example: stringUpdate,
        editorialNote: stringUpdate,
        altLabel: altLabelUpdate,
        prefLabel: prefLabelUpdate,
    };

    const field = Object.keys(newData)[0];
    if (updatePattern[field]) {
        return updatePattern[field].call(this, oldData, field, newData[field]);
    }
    return this.errorResponse('Internal Error: Update pattern not found');
}

// Check if a Concept being added is already in a ConceptScheme
function duplicateInScheme(parentId, skosId) {
    const topConcept = this.getRelated(parentId, 'hasTopConcept');
    const allNarrower = [parentId, ...topConcept].flatMap((id) => this.conceptAllNarrower(id)); // All the narrower concepts
    return [...topConcept, ...allNarrower].find((nId) => nId === skosId);
}

// Add a new narrower Concept and it's children to another Concept
function conceptAddNarrower({
    parent,
    narrower,
    schemeId,
}) {
    const { id: nodeId } = narrower;
    const { id: parentId } = parent;
    const action = {}; // Response with set of requested updates to the database
    console.log(`Add narrower term ${narrower.id} to ${parent.id}`);

    if (duplicateInScheme.call(this, parentId, nodeId)) {
        return this.errorResponse('This Concept is already present in this ConceptScheme, no duplicates allowed')
    }

    const allNarrower = this.conceptAllNarrower(nodeId); // All the narrower concepts
    const allSchemes = [...this.conceptInScheme(parentId), schemeId];
    const addToScheme = [nodeId, ...allNarrower].flatMap((nId) =>
        (allSchemes.map((sId) => this.createAction(nId, sId, 'inScheme'))
        ));
    const addNarrower = this.createAction(parentId, nodeId, 'narrower');
    const addBroader = this.createAction(nodeId, parentId, 'broader');
    action.create = [addNarrower, addBroader, ...addToScheme];

    return {
        error: null,
        action,
    };
}

function conceptAddTopConcept({
    parent,
    topConcept,
    schemeId,
}) {
    const { id: nodeId } = topConcept;
    const { id: parentId } = parent;
    console.log(`Add topConcept ${nodeId} to scheme ${parentId}`);
    const action = {}; // Response with set of requested updates to the database

    if (duplicateInScheme.call(this, parentId, nodeId)) {
        return this.errorResponse('This Concept is already present in this ConceptScheme, no duplicates allowed')
    }

    const allNarrower = this.conceptAllNarrower(nodeId); // All the narrower concepts
    const addToScheme = [nodeId, ...allNarrower].map((nId) => this.createAction(nId, schemeId, 'inScheme'));
    const addTopConcept = [
        this.createAction(parentId, nodeId, 'hasTopConcept'),
        this.createAction(nodeId, parentId, 'topConceptOf')
    ];
    action.create = [...addTopConcept, ...addToScheme];

    return {
        error: null,
        action,
    };
}

// Remove the Concept from the scheme
// Check if there are any narrower concepts and remove them from the scheme
// If any of the concepts are not in an unrelated scheme, also remove the narrower edge
function conceptRemoveNarrower({
    parent,
    concept,
    schemeId,
    skipNarrower = false, // If set true this will skip the narrower check, used when doing a move
}) {
    const { id: nodeId } = concept;
    const { id: parentId } = parent;
    console.log(`Remove narrower term ${nodeId} from ${parentId}`);

    // Which schemes should this Concept be removed from
    const parentSchemes = this.conceptInScheme(parentId); // Only check the schemes the parent node is in
    const deleteFromScheme = this.conceptSchemeRemoval(nodeId, parentSchemes);

    // Break the broader/narrower relationship with the parent
    const deleteNarrower = [
        deleteAction(parentId, nodeId, 'narrower'),
        deleteAction(nodeId, parentId, 'broader'),
    ];

    // If narrower terms are not in any other schemes, remove the narrower edges
    if (!skipNarrower) deleteNarrower.push(...this.removeNarrower(nodeId, schemeId));

    return {
        error: null,
        action: { delete: [...deleteFromScheme, ...deleteNarrower] },
    };
}

// Remove the topConcept from the scheme
// Check if there are any narrower concepts and remove them from the scheme
// If any of the concepts are not in an unrelated scheme, also remove the narrower edge
function conceptRemoveTopConcept({
    parent,
    concept,
    schemeId,
    skipNarrower = false, // If set true this will skip the narrower check, used when doing a move
}) {
    console.log(`Action to remove topConcept ${concept.id} from scheme ${parent.id}`);
    const { id: nodeId } = concept;
    const { id: parentId } = parent;
    let error = null;

    const allNarrower = this.conceptAllNarrower(nodeId); // All the narrower concepts

    // Remove the deleted node and any narrower nodes from the scheme
    const deleteFromScheme = [nodeId, ...allNarrower].map((c) => deleteAction(c, schemeId, 'inScheme'));
    // Remove the target node from the parent node
    const deleteTopConcept = [
        deleteAction(parentId, nodeId, 'hasTopConcept'),
        deleteAction(nodeId, parentId, 'topConceptOf'),
    ]
    // If narrower terms are not in any other schemes, remove the narrower/broader edges
    const deleteNarrower = skipNarrower ? [] : this.removeNarrower(nodeId, schemeId);

    return {
        error,
        action: { delete: [...deleteTopConcept, ...deleteFromScheme, ...deleteNarrower] },
        value: null,
    };
}

function conceptMoveNarrower(params) {
    console.log(`Action to move Narrower`);
    return conceptRemoveNarrower.call(this, {
        ...params,
        skipNarrower: true,
    });
}

function conceptMoveTopConcept(params) {
    console.log(`Action to move topConcept`);
    return conceptRemoveTopConcept.call(this, {
        ...params,
        skipNarrower: true,
    });
}

/**
 *
 * @param params
 * @returns {{title: string, message: string, status: string}}
 */
function deleteConcept(params) {
    console.log('Action to Delete a Concept');
    const { oldData } = params;
    console.log(this.edges[oldData.id]);
    const checkChildren = this.childProperties(oldData.id);
    if (checkChildren.length) {
        return this.errorResponse('The Concept you are attempting to delete has child entities, please remove these first')
    }
    return {
        error: null,
        action: {
            delete: [
                {
                    id: oldData.id,
                    type: oldData.type,
                },
                {
                    ...oldData.prefLabel
                }
            ]
        }
    };
}

// A set of actions that can be performed on the SKOS vocabulary
const updateFunctions = {
    conceptAddTerm,
    conceptFieldChange,
    conceptAddNarrower,
    conceptAddTopConcept,
    conceptRemoveNarrower,
    conceptMoveNarrower,
    conceptRemoveTopConcept,
    conceptMoveTopConcept,
    deleteConcept,
};

function changeVocabRequest(updateAction, params) {
    return updateFunctions[updateAction].call(this, params);
}

function setupSearch(data) {
    const allTerms = Object.keys(data.nodes).filter((key) => data.nodes[key].type === 'skos:Concept')
        .map((key) => data.nodes[key]);
    return new FuzzySearch(allTerms, ['prefLabel'], {
        caseSensitive: false,
        sort: true,
    })
}

export default async function createSkosDictionary(accessToken) {
    const dict = await getDictionary('skos', accessToken);
    const haystack = setupSearch(dict)
    const vocabProto = Object.create({
        generateId,
        getNode,
        getType,
        getRelated,
        errorResponse,
        update,
        skosConcept,
        skosConceptScheme,
        getEntity,
        childProperties,
        allChildProperties,
        parentProperties,
        createAction,
        schemeHasConcept,
        conceptInScheme,
        duplicateInScheme,
        conceptIsTopConcept,
        conceptSchemeRemoval,
        removeNarrower,
        conceptAllNarrower,
        changeVocabRequest,
        haystack,
    });
    return Object.assign(vocabProto, dict);
}
