/**
 * Setup the primary container for the App
 * - Router, the React router component for page navigation
 * - ChakraProvider, the Chakra wrapper with the default styles applied
 */

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import customTheme from './style/customTheme';

function AppContainer() {
    return (
        <ChakraProvider theme={customTheme} h="100%">
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ChakraProvider>
    );
}

export default AppContainer;
