import { nanoid } from 'nanoid';

const identifierOfScope = (identifier, scope) => {
    return identifier.filter((id) => id.identifierScope === scope)
        .map((id) => id.identifierValue)[0];
};

const makeArray = ((v) => Array.isArray(v) ? v : [v]);

const removeKeys = ['Context', 'For', 'name', 'description', 'schemaVersion', 'identifier', 'entityType', 'contextType'];

export default async function omcTree(omc, identifierScope) {

    // Extract top level attributes used by the graph for labels etc.
    const parseEntity = ((ent, scope) => {
        if (!ent || (!ent.entityType && !ent.schemaVersion)) return null // null or just a reference, not full entity
        const identifier = identifierOfScope(ent.identifier, scope);
        return {
            nodeId: nanoid(),
            entityType: ent.entityType ? ent.entityType : 'Unknown',
            name: ent.name ? ent.name : 'Unknown',
            identifier,
            omc: ent,
        };
    });

    function traverseTree(omc, scope) {

        // Build the Context and related child nodes
        const parseContext = ((node) => {
            const children = omc.Context.map((cxt) => {
                const relations = Object.keys(cxt)
                    .filter((k) => !removeKeys.includes(k))
                    .filter((k) => cxt[k] !== null);
                if (relations.length === 0) return null; // Empty Contexts
                const children = relations.flatMap((edge) => {
                    const entKeys = Object.keys(cxt[edge])
                        .filter((ek) => cxt[edge][ek] !== null);
                    const trgt = entKeys.flatMap((entType) => cxt[edge][entType].map((e) => {
                        const cxtNode = traverseTree(e, scope);
                        return cxtNode !== null ?
                            {
                                ...cxtNode, ...{
                                    edge,
                                    // entityType: entType,
                                    entityType: cxtNode.entityType,
                                },
                            }
                            : null;
                    }));
                    return trgt.filter((t) => t !== null);
                });
                const node = parseEntity(cxt, scope);
                return { ...node, ...{ children } };
            })
                .filter((n) => n !== null);
            return children;
        });

        // Parse any intrinsic relations that are not Context
        const parseIntrinsic = ((node, eName) => {
            const childOmc = makeArray(omc[eName]);
            return childOmc.map((relOmc) => parseEntity(relOmc, scope))
                .filter((n) => n !== null);
        })

        if (omc == null) return null;
        const node = parseEntity(omc, scope);
        const iEdges = Object.keys(omc)
            .filter((e) => /^[A-Z]/.test(e));

        iEdges.forEach((eName) => {
            const children = eName === 'Context' ? parseContext(node) : parseIntrinsic(node, eName)
            node.children = node.children ? [...node.children, ...children] : children;
        });
        return node;
    }

    // The tree will accept only a single root node, if this is an array, only process the first hierarchy
    const omcTree = Array.isArray(omc) ? omc[0] : omc;
    return traverseTree(omcTree, identifierScope)
}
