import { OmcModel } from './omcUtil.mjs';

const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const apiPath = '/api/omc/entity';
export default async function getEntity(params, accessToken) {
    const {
        identifierScope,
        identifierValue,
        currentProject: project
    } = params;
    console.log(`Scope: ${identifierScope}, Value: ${identifierValue}`);
    const url = `${labkoatApiUrl}${apiPath}?identifierScope=${identifierScope}&identifierValue=${identifierValue}&project=${project}`;
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Cache-Control': 'no-cache',
            Authorization: `Bearer ${accessToken}`,
        },
    };
    try {
        const res = await fetch(url, options);
        if (res.status !== 200 ) {
            console.log(`Fetch Error: ${res.status} - ${res.statusText}`);
            console.log(`${identifierValue} - Not available`);
            return null;
        }
        const omc = await res.json();
        return OmcModel(omc);
    } catch (err) {
        console.log(err);
        return null;
    }
}
