import React, { useEffect, useState } from 'react';
import {
    Box,
    VStack,
} from '@chakra-ui/react';
import { useOktaAuth } from '@okta/okta-react';

import TaskList from '../Components/Layout/Utilities/TaskList';

// const otioURL = 'https://service.labkoat.media/storage/get?identifierScope=labkoat&identifierValue=astsc%2Fi8baZCWRp6ltMmjvvuUst';
const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const eventsPath = '/api/approval/events';

async function fetchEvents(accessToken) {
    try {
        const url = labkoatApiUrl + eventsPath;
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const res = await fetch(url, options);
        const eventsData = await res.json();
        // console.log(eventsData);
        return eventsData;
    } catch (err) {
        console.log('Error');
        console.log(err);
        return [];
    }
}

/*
 This function is called when a button on the list is clicked

 For the review Task, this will look for the OTIO in the Asset Manifest and return this in a URL to OpenRV
 */
async function listFunction(accessToken, omc) {
    console.log('Approval button');
    const assets = omc.functionalCharacteristics.functionalProperties.Asset; // The Task
    const seqChron = assets.filter((ent) => ent.AssetFC.functionalType === 'sequenceChronology');
    const essenceIdentifier = seqChron[0].AssetSC.identifier;
    console.log(essenceIdentifier);
    const essenceValue = encodeURIComponent(essenceIdentifier[0].identifierValue);
    const otioURL = `https://service.labkoat.media/storage/get?identifierScope=labkoat&identifierValue=${essenceValue}`;
    console.log(otioURL);
    if (window.rvsession) {
        window.setTimeout(() => {
            window.rvsession.evaluate(`sendInternalEvent("movielabs-load-otio", "${otioURL}")`);
        }, 0);
    }
}

function Approval() {
    const [approvalList, updateApprovalList] = useState([]);
    const [isLoading, updateLoading] = useState(true);
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();

    useEffect(() => {
        fetchEvents(accessToken)
            .then((data) => {
                updateApprovalList(data);
                updateLoading(false);
            })
            .catch((err) => {
                console.log(err);
                updateLoading(false);
            });
    }, []);

    useEffect(() => {
        // const accessToken = oktaAuth.getAccessToken();
        if (window.rvsession) {
            window.setTimeout(() => {
                window.rvsession.evaluate(`sendInternalEvent("movielabs-login", "${accessToken}")`);
            }, 0);
        }
    });

    return (
        <Box m={3}>
            <TaskList
                taskType={['review']}
                listFunction={listFunction}
            />
            <VStack
                spacing={4}
                align="stretch"
            />
        </Box>
    );
}

export default Approval;
