/**
 * A set of default values for the attributes of the tree graph
 */


/**
 * Base description for node shapes
 * @type {{width: number, radius: number, height: number}}
 */

const pillShapeNode = {
    width: 200,
    height: 20,
    radius: 5,
};

export const fontHeight = 8; // Height of the font used in nodes (px)
export const nodeSpaceVertical = 26; // Vertical spacing between nodes, should greater than highest node shape
export const minSpaceHorizontal = 300 // The minimum horizontal space between nodes, should be greater than the width of the node shape
export const statusColor = {
    published: '#239B56',
    review: '#AF601A',
    proposed: '#2874A6',
    'omc-schema': '#7D3C98',
    deprecated: '#B03A2E',
};

// Which node types can be dropped on to which other node types
export const canDrop = {
    'omc:Root': ['omc:Entity'],
    'omc:Container': ['omc:Root'],
    'omc:Entity': ['omc:Root', 'omc:Container', 'omc:Entity', 'omc:Property'],
    'omc:Property': ['omc:Container', 'omc:Entity', 'omc:Property'],
    'omc:ControlledValue': ['omc:Property', 'omc:ControlledValue'],
};

/**
 * Parameters for presenting nodes for each entity Type, style
 *
 * Colors: https://htmlcolorcodes.com/color-chart/
 */

export const nodeParams = {
    'Default': {
        style: {
            color: '#555555',
            dragTarget: '#555555',
            dragColor: '#F1C40F',
            shape: {
                width: 20,
                height: 22,
                radius: 5,
            },
        },
        label: 'Default',
        query: {
            allow: false
        }
    },
    'draggable': {
        style: {
            color: '#F6DDCC',
            expand: '#E59866',
            dragTarget: '#FF0033',
            dragColor: '#FF7043',
            shape: pillShapeNode,
        },
        label: 'Root',
        query: {
            allow: true
        },
        contextMenu: [
            {
                key: '1a',
                label: 'Cancel',
                action: 'cancelDrag',
            },
        ],
    },
    'omc:Root': {
        style: {
            color: '#F6DDCC',
            expand: '#E59866',
            dragTarget: '#FF0033',
            dragColor: '#FF7043',
            shape: pillShapeNode,
        },
        label: 'Root',
        query: {
            allow: true
        },
        contextMenu: null,
    },
    'omc:Container': {
        style: {
            color: '#F6DDCC',
            expand: '#E59866',
            dragTarget: '#FF0033',
            dragColor: '#FF7043',
            shape: pillShapeNode,
        },
        label: 'Container',
        query: {
            allow: true
        },
        contextMenu: [
            {
                key: 'e2',
                label: 'Remove Container',
                action: 'removeProperty',
            },
        ],
    },
    'omc:Entity': {
        style: {
            color: '#E8DAEF',
            expand: '#BB8FCE',
            dragTarget: '#9C27B0',
            dragColor: '#BA68C8',
            shape: pillShapeNode,
        },
        label: 'Entity',
        query: {
            allow: false
        },
        contextMenu: [
            {
                key: 'e1',
                label: 'Move Entity',
                action: 'moveProperty',
            },
            {
                key: 'e2',
                label: 'Copy Entity',
                action: 'copyProperty',
            },
            {
                key: 'e3',
                label: 'Remove Entity',
                action: 'removeProperty',
            },
        ],
    },
    'omc:Property': {
        style: {
            color: '#D4EFDF',
            expand: '#7DCEA0',
            dragTarget: '#2E7D32',
            dragColor: '#4CAF50',
            shape: pillShapeNode,
        },
        label: 'Property',
        query: {
            allow: true
        },
        contextMenu: [
            {
                key: 'p1',
                label: 'Move Property',
                action: 'moveProperty',
            },
            {
                key: 'p2',
                label: 'Copy Property',
                action: 'copyProperty',
            },
            {
                key: 'p3',
                label: 'Remove Property',
                action: 'removeProperty',
            },
        ],
    },
    'omc:ControlledValue': {
        style: {
            color: '#E3F2FD',
            expand: '#90CAF9',
            dragTarget: '#1565C0',
            dragColor: '#1E88E5',
            shape: pillShapeNode,
        },
        label: 'Controlled Value',
        query: {
            allow: true
        },
        contextMenu: [
            {
                key: 'cv1',
                label: 'Move Controlled Value',
                action: 'moveControlledValue',
            },
            {
                key: 'cv2',
                label: 'Remove Controlled Value',
                action: 'removeControlledValue',
            },
        ],
    },
    'omc:Schema': {
        style: {
            color: '#E3F2FD',
            expand: '#90CAF9',
            dragTarget: '#1565C0',
            dragColor: '#1E88E5',
            shape: pillShapeNode,
        },
        label: 'Schema',
        query: {
            allow: true
        },
        contextMenu: [
            {
                key: '1a',
                label: 'Remove Value',
                action: 'removeSchema',
            }
        ],
    },
};
