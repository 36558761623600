import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { SkosDictionaryProvider } from './hooks/SkosDictionaryContext';

import config from './config';

import MessagingAuth from './Components/Auth/MessagingAuth';
import Routes from './Components/Auth/Routes';
import Navbar from './Components/Layout/Navbar/Navbar';
import { OmcDictionaryProvider } from './hooks/OmcDictionaryContext';

// rene@labkoat.media
// MovieLabs&EuropaTest1

const oktaAuth = new OktaAuth(config.oidc);
const { labkoatMessaging } = config;
MessagingAuth.config(labkoatMessaging);

function App() {
    // const history = useHistory();
    const navigate = useNavigate();

    const customAuthHandler = () => {
        navigate('/login');
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
        // const destinationUri = originalUri === null ? '/' : originalUri;
        // history.replace(toRelativeUrl(destinationUri, window.location.origin));
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <Navbar />
            <SkosDictionaryProvider>
                <OmcDictionaryProvider>
                    <Routes />
                </OmcDictionaryProvider>
            </SkosDictionaryProvider>
        </Security>
    );
}

export default App;
