/**
 * Use Ag-Grid to display the SKOS table
 */

import React, {
    useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
    Box, IconButton,
    Text,
    Icon,
} from '@chakra-ui/react';
import { AiOutlineMinusCircle, AiOutlinePartition, AiOutlinePlusCircle } from 'react-icons/ai';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { LicenseManager } from '@ag-grid-enterprise/core';
import ArrayEditor from './ArrayEditor';

import '@ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import '@ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid

import { downloadSkos } from '../../../services/vocabulary/api.mjs';
import { createSkosTable } from '../../../services/vocabulary/skosTable.mjs';
import { useSkosDictionary } from '../../../hooks/SkosDictionaryContext';

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ClipboardModule,
    ExcelExportModule,
    MenuModule,
]);

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-059153}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{MovieLabs}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MovieLabs}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{MovieLabs}_need_to_be_licensed___{MovieLabs}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_April_2025}____[v3]_[01]_MTc0NTUzNTYwMDAwMA==b1fbd528fc257fa86809087a66529682');

const emptyRow = {
    altLabel: [],
    definition: '',
    id: 'inputRow',
    inScheme: [],
    language: 'en',
    prefLabel: {},
    type: 'skos:Concept',
};

const inputPrompts = {
    action: '',
    altLabel: 'Alt Label...',
    status: 'Status...',
    definition: 'Definition...',
    scheme: ' ---',
    inScheme: ' --- ',
    language: 'en',
    prefLabel: 'Label...',
    example: 'Example...',
    editorialNote: 'Editorial Note...',
};

function SkosTable({
    updateChart = () => {}, // Function to update the chart
    updateTable = () => {}, // Function to change the scheme displayed in the table
    displayMessage = () => {}, // Function to display a message
    updateDragNode = () => {}, // Call to change the draggable node
    selectedScheme = [null], // The SKOS Concept Scheme to display
    containerDimensions = { width: 0, height: 0 }, // The dimensions of the container for the tree
}) {
    // console.log('Skos Table', selectedScheme);
    // Column Definitions: Defines the columns to be displayed.
    const [rowData, setRowData] = useState([]);
    const [inputRow, setInputRow] = useState(emptyRow); // The input row for adding new data
    const skosDictionary = useSkosDictionary();

    const defaultColDef = useMemo(() => ({
        valueFormatter: ((params) => {
            if (params.node.rowPinned !== 'top') return undefined;
            const { field } = params.colDef;
            if (Array.isArray(params.value)) {
                return params.value.length ? params.value : inputPrompts[field];
            }
            return (typeof params.value === 'string' && params.value !== '')
                ? params.value
                : inputPrompts[field] || `Missing ${field}`;
        }),
    }));

    const getContextMenuItems = useCallback(
        () => [
            'copy',
            {
                name: 'Export',
                subMenu: [
                    'csvExport',
                    'excelExport',
                    {
                        name: 'Turtle',
                        action: async () => {
                            console.log('Export TTL');
                            await downloadSkos('ttl');
                        },
                    },
                    {
                        name: 'JSON-LD',
                        action: async () => {
                            console.log('Export JSON-LD');
                            await downloadSkos('json');
                        },
                    },
                ],
            },
        ],
        [window],
    );

    // Process an update and handle any returned errors
    const processRequest = (async (actionVerb, params) => {
        const updateResponse = await skosDictionary.changeVocabRequest(actionVerb, params);
        if (updateResponse.error) {
            displayMessage(updateResponse.error);
        } else {
            const dbResponse = await updateChart({ action: updateResponse.action });
            updateTable(selectedScheme); // Update the table
        }
    });

    // Called when cell editing is complete, the event passes the new value in for processing
    const onCellEditRequest = useCallback((async (event) => {
        const oldData = event.data;
        const { field } = event.colDef;
        const newData = { ...oldData }; // Clone the existing data

        if (event.rowPinned !== 'top') { // Is this a new item, or existing data
            await processRequest('conceptFieldChange', {
                oldData,
                newData: { [field]: event.newValue },
            }); // Update the core data
        } else {
            if (field === 'prefLabel') {
                newData[field] = {
                    language: 'en',
                    value: event.value,
                };
            } else {
                newData[field] = event.value;
            }
            setInputRow(newData); // Update the data in the row
        }
    }), [skosDictionary, selectedScheme]);

    // Called for the submission of a new term
    const submitNewTerm = (async (input) => {
        await processRequest('conceptAddTerm', input);
        setInputRow(emptyRow); // Clear the input row
    });

    const removeTerm = (async (input) => {
        await processRequest('deleteConcept', { oldData: input });
    });

    const customButtonRenderer = ((params) => {
        const { node } = params;
        return node.rowPinned === 'top'
            ? (
                <IconButton
                    variant="outline"
                    size="sm"
                    aria-label="Submit"
                    icon={<Icon as={AiOutlinePlusCircle} w={6} h={6} />}
                    onClick={() => submitNewTerm(params.data)}
                />
            )
            : (
                <Box>
                    <IconButton
                        variant="ghost"
                        size="sm"
                        aria-label="Position"
                        icon={<Icon as={AiOutlineMinusCircle} w={6} h={6} />}
                        onClick={() => removeTerm(params.data)}
                    />
                    <IconButton
                        variant="ghost"
                        size="sm"
                        aria-label="Position"
                        icon={<Icon as={AiOutlinePartition} w={6} h={6} />}
                        onClick={() => updateDragNode(params.data.id)}
                    />
                </Box>
            );
    });

    const getRowStyle = useCallback(({ node }) => (
        node.rowPinned ? {
            fontWeight: 'bold',
            fontStyle: 'italic',
            backgroundColor: '#85C1E9',
        } : {}
    ), []);

    useEffect(() => {
        if (!skosDictionary || !selectedScheme) return; // No table data yet
        const skosRows = createSkosTable(selectedScheme, skosDictionary);
        setRowData(skosRows);
    }, [selectedScheme]);

    if (!skosDictionary) {
        return (
            <Box>
                <Text>Loading Vocabulary Data</Text>
            </Box>
        );
    }

    const columnDefinitions = ([
        {
            field: 'action',
            headerName: '',
            cellRenderer: customButtonRenderer,
        },
        {
            field: 'prefLabel',
            headerName: 'Preferred Label',
            valueGetter: ((p) => (p.data.prefLabel ? p.data.prefLabel.value : 'ERROR')),
            editable: true,
            sortable: true,
            filter: true,
        },
        {
            field: 'inScheme',
            valueGetter: ((p) => (p.data.inScheme ? p.data.inScheme.map((v) => v.prefLabel) : 'Testing')),
        },
        {
            field: 'status',
            sortable: true,
            editable: true,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorParams: {
                values: ['published', 'review', 'proposed', 'deprecated', 'omc-schema'],
            },
        },
        {
            field: 'altLabel',
            editable: true,
            headerName: 'Alt. Label',
            valueGetter: ((p) => (p.data.altLabel ? p.data.altLabel.map((v) => v.value) : 'Test altLabel')),
            cellEditor: ArrayEditor,
            cellEditorPopup: true,
        },
        {
            field: 'definition',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
        },
        {
            field: 'example',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
        },
        {
            field: 'editorialNote',
            editable: true,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
        },
    ]);

    const gridParams = {
        autoSizeStrategy: {
            type: 'fitCellContents',
        },
        rowData,
        columnDefs: columnDefinitions,
        defaultColDef,
        readOnlyEdit: true,
        onCellEditRequest,
        reactiveCustomComponents: true,
        getContextMenuItems,
        getRowId: (params) => params.data.id,
        pinnedTopRowData: [inputRow],
        getRowStyle,
        // browserAutoComplete: false,
    };

    return (
        <Box
            ml={1}
            mr={1}
            className="ag-theme-quartz" // applying the grid theme
            h={containerDimensions.height}
        >
            <AgGridReact
                {...gridParams}
                modules={[
                    ClientSideRowModelModule,
                    RichSelectModule,
                ]}
            />
        </Box>
    );
}

SkosTable.propTypes = {
    updateChart: PropTypes.func, // Call to update the chart and database
    updateTable: PropTypes.func, // Function to change the scheme displayed in the table
    displayMessage: PropTypes.func, // Function to display an error message
    updateDragNode: PropTypes.func, // Call to change the draggable node
    triggerRender: PropTypes.number, // Forces a re-render of the tree after table has updated
    treeId: PropTypes.string, // Unique Id for the tree container in DOM
    selectedScheme: PropTypes.arrayOf(PropTypes.string), // The SKOS Concept Scheme to display
    containerDimensions: PropTypes.shape({ // The dimensions of the container for the tree
        width: PropTypes.number,
        height: PropTypes.number,
    }),
};

export default React.memo(SkosTable);
