/**
 * Maintain an in memory cache of the SKOS vocabulary for the UI to use
 *
 * Provide functions to update and query the cache
 */

export function createSkosTable(schemeId, skosDict) {
    const tempConcepts = schemeId.flatMap((sId) => skosDict.schemeHasConcept(sId))

    // De-dupe nodes, this happens if a node appears in multiple schemes
    const deDupeConcepts = tempConcepts.reduce((obj, omcId) => ({
        ...obj,
        [omcId]: true,
    }), {});

    const sId = schemeId[1] || null; // The first scheme is the primary scheme
    return Object.keys(deDupeConcepts)
        .map((omcId) => {
            const skosEntity = skosDict.getEntity(omcId);
            const primaryScheme = skosEntity.inScheme.filter((sch) => sch.id === sId)[0] || null;
            const otherScheme = skosEntity.inScheme.filter((sch) => sch.id !== sId);
            const inScheme = primaryScheme ? [primaryScheme, ...otherScheme] : otherScheme
            return {
                ...skosEntity,
                inScheme,
            }
        }); // A row of Concept data in the scheme
}
