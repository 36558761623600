import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';

import Loading from './Loading';

function SecureRoute() {
    const { oktaAuth, authState } = useOktaAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authState) {
            return;
        }

        if (!authState.isAuthenticated) {
            const originalUri = toRelativeUrl(window.location.href, window.location.origin);
            oktaAuth.setOriginalUri(originalUri);
            // oktaAuth.signInWithRedirect();
            navigate('/login');
        }
    }, [oktaAuth, !!authState]); // [oktaAuth, !!authState, authState.isAuthenticated]);

    if (!authState || !authState.isAuthenticated) {
        return (<Loading />);
    }
    return (<Outlet />);
}

export default SecureRoute;
