/**
 * Main Page Component for displaying a tree based graph of OMC data
 */

import React from 'react';

import OmcJsonEditorContainer from '../Components/Diagrams/OmcJsonEditor/OmcJsonEditorContainer';
import LoadingSpinner from '../Components/Layout/Utilities/LoadingSpinner';

import { useOktaAuth } from '@okta/okta-react';
import { useOmcDictionary } from '../hooks/OmcDictionaryContext';
import { useSkosDictionary } from '../hooks/SkosDictionaryContext';

function OmcJsonEditor() {
    const { authState } = useOktaAuth();
    const omcDictionary = useOmcDictionary();
    const skosDictionary = useSkosDictionary();

    // If the user is not yet authorized or the vocabulary is not loaded return the spinner
    return (!authState.isAuthenticated || !omcDictionary || !skosDictionary)
        ? (<LoadingSpinner />)
        : (
            <OmcJsonEditorContainer
                accessToken={authState.accessToken.accessToken}
            />
        );
}

export default OmcJsonEditor;
