import React from 'react';
import {
    Box,
    Image,
    Heading,
    Flex,
} from '@chakra-ui/react';

function TitleArt() {
    return (
        <Flex
            justify="center"
            direction="column"
            alignItems="center"
        >
            <Heading>
                Hyperspace Madness
            </Heading>
            <Box>
                <Image
                    src="images/sven-title.png"
                    alt="Title Art"
                />
            </Box>
        </Flex>
    );
}

export default function Home() {
    return (
        <div>
            <TitleArt />
        </div>
    );
}
