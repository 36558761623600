/**
 * Main Page Component for displaying a tree based graph of OMC data
 */

import React from 'react';

import SkosEditorContainer from '../Components/Diagrams/SkosEditor/SkosEditorContainer';
import LoadingSpinner from '../Components/Layout/Utilities/LoadingSpinner';

import { useOktaAuth } from '@okta/okta-react';
import { useSkosDictionary } from '../hooks/SkosDictionaryContext';

function SkosEditor() {
    const { authState } = useOktaAuth();
    const skosDictionary = useSkosDictionary();

    // If the user is not yet authorized or the vocabulary is not loaded return the spinner
    return (!authState.isAuthenticated || !skosDictionary)
        ? (<LoadingSpinner />)
        : (
            <SkosEditorContainer
                accessToken={authState.accessToken.accessToken}
            />
        );
}

export default SkosEditor;
