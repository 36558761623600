import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

function LoadingSpinner() {
    return (
        <Center>
            <Spinner thickness="4px" color="blue.600" emptyColor="blue.50" size="xl" label="loading"/>
        </Center>
    );
}

export default LoadingSpinner;
