const labkoatApiUrl = process.env.REACT_APP_LABKOAT_API_BASE;
const assetInfoPath = '/api/asset/info';
export async function fetchAssetInfo(accessToken, functionalType) {
    try {
        const url = `${labkoatApiUrl}${assetInfoPath}?functionalType=${functionalType}`;
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const res = await fetch(url, options);
        return res.json();
    } catch (err) {
        console.log('Error');
        console.log(err);
        return [];
    }
}

export async function dummy() {
    console.log('Dummy');
}
